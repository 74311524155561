import React from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Pressable,
  ScrollView,
  Icon,
  CloseIcon,
  Divider,
  Chip,
} from '@gluestack-ui/themed';
import { Dimensions } from 'react-native';
import { Motion } from '@legendapp/motion';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function ResumeAnalysisDrawer({ isOpen, onClose, analysisData }) {
  const ParserAnalysis = JSON.parse(analysisData)
  console.log(ParserAnalysis, "ParserAnalysis")
  if (!isOpen) return null;

  return (
    <Motion.View
    key="resumeAnalysisDrawer"
    style={{
      position: 'fixed',
      zIndex: 10001,
      width: windowWidth > 480 ? `${windowWidth * 0.35}px` : windowWidth,
      backgroundColor: 'white',
      top: 0,
      bottom: 0,
      right: 0,
      borderLeftWidth: 1,
      borderColor: '#E5E5E5',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    }}
    initial={{
      x: windowWidth > 480 ? windowWidth * 0.50 : windowWidth,
      opacity: 0,
    }}
    animate={{
      x: isOpen ? 0 : windowWidth > 480 ? windowWidth * 0.35 : windowWidth,
      opacity: isOpen ? 1 : 0,
    }}
    transition={{ type: 'spring', bounciness: 200 }}
    exit={{ x: windowWidth * 0.35, opacity: 0 }}
  >
        <Box>
        <Pressable
          onPress={onClose}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          py={10}
          px={25}
          gap={5}
          w={'fit-content'}
        >
          <Icon as={CloseIcon} color={'#1B3444'} size={'sm'} />
          <Text fontSize={12} fontFamily="$fontHeading" color="#1B3444">
            CLOSE
          </Text>
        </Pressable>
        <Divider my={5} />
      </Box>
        <Box flex={1} overflow="hidden">
        <ScrollView 
          style={{ height: '100%' }}
          contentContainerStyle={{ padding: 20 }}
        >
          <Text
            fontSize={24}
            fontWeight="700"
            fontFamily="$fontHeading"
            mb={6}
            color="#1B3444"
          >
            Resume Analysis
          </Text>
          <VStack space={6}>
            {analysisData ? (
              <Box>
                {/* General Info Section */}
                <Box bg="$backgroundLight50" p={4} borderRadius={8} mb={6}>
                  <Text fontSize={18} fontWeight="600" color="#1B3444" mb={3}>General Info</Text>
                  <VStack space={2}>
                    <Text fontSize={15}>
                      <Text fontWeight="500">Name:</Text> {ParserAnalysis.general_info.full_name}
                    </Text>
                    <Text fontSize={15}>
                      <Text fontWeight="500">Email:</Text> {ParserAnalysis.general_info.email}
                    </Text>
                    <Text fontSize={15}>
                      <Text fontWeight="500">Current Position:</Text> {ParserAnalysis.general_info.current_position}
                    </Text>
                    <Text fontSize={15}>
                      <Text fontWeight="500">Phone Number:</Text> {ParserAnalysis.general_info.phone} 
                    </Text>
                    <Text fontSize={15}>
                      <Text fontWeight="500">Experience:</Text> {ParserAnalysis.general_info.years_experience} Years
                    </Text>
                  </VStack>
                </Box>

                {/* Skills Analysis Section */}
                <Box bg="$backgroundLight50" p={4} borderRadius={8} mb={6}>
                  <Text fontSize={18} fontWeight="600" color="#1B3444" mb={3}>Skills Analysis</Text>
                  
                  <VStack space={4}>
                    <Box>
                      <Text fontSize={16} fontWeight="500" mb={2}>Matched Skills</Text>
                      <HStack flexWrap="wrap" space={2}>
                        {ParserAnalysis.skills_analysis.matched_skills?.map((skill, index) => (
                          <Box
                            key={index}
                            bg="$primary50"
                            px={3}
                            py={1}
                            borderRadius={16}
                            mb={2}
                          >
                            <Text color="$primary600" fontSize={14}>{skill}</Text>
                          </Box>
                        ))}
                      </HStack>
                    </Box>

                    <Box>
                      <Text fontSize={16} fontWeight="500" mb={2}>Resume Skills</Text>
                      <HStack flexWrap="wrap" space={2}>
                        {ParserAnalysis.skills_analysis.resume_skills?.map((skill, index) => (
                          <Box
                            key={index}
                            bg="$secondary50"
                            px={3}
                            py={1}
                            borderRadius={16}
                            mb={2}
                          >
                            <Text color="$secondary600" fontSize={14}>{skill}</Text>
                          </Box>
                        ))}
                      </HStack>
                    </Box>
                    <Box>
                      <Text fontSize={16} fontWeight="500" mb={2}>Required Skills</Text>
                      <HStack flexWrap="wrap" space={2}>
                        {ParserAnalysis.job_details.data.getPreferences.skills?.map((skill, index) => (
                          <Box
                            key={index}
                            bg="$secondary50"
                            px={3}
                            py={1}
                            borderRadius={16}
                            mb={2}
                          >
                            <Text color="$secondary600" fontSize={14}>{skill}</Text>
                          </Box>
                        ))}
                      </HStack>
                    </Box>
                  </VStack>
                </Box>

                {/* Business Outcomes Section */}
                <Box bg="$backgroundLight50" p={4} borderRadius={8} mb={6}>
                  <Text fontSize={18} fontWeight="600" color="#1B3444" mb={3}>Business Outcomes</Text>
                  <VStack space={2}>
                    {ParserAnalysis.business_outcomes.outcomes?.map((outcome, index) => (
                      <Box
                        key={index}
                        bg="white"
                        p={3}
                        borderRadius={6}
                        borderWidth={1}
                        borderColor="$borderLight200"
                      >
                        <Text fontSize={15}>{outcome.outcome}</Text>
                      </Box>
                    ))}
                  </VStack>
                </Box>
                {/* Relevant Experience Section */}
                <Box bg="$backgroundLight50" p={4} borderRadius={8}>
                  <Text fontSize={18} fontWeight="600" color="#1B3444" mb={3}>Relevant Experience</Text>
                  <VStack space={4}>
                    {ParserAnalysis.experience_analysis.relevant_experience?.map((experience, index) => (
                      <Box
                        key={index}
                        bg="white"
                        p={4}
                        borderRadius={6}
                        borderWidth={1}
                        borderColor="$borderLight200"
                      >
                        <Text fontSize={16} fontWeight="500" mb={2}>{experience.alignment_with_job}</Text>
                        <Text fontSize={14} color="$textLight600" mb={3}>Duration: {experience.duration}</Text>
                        <Text fontSize={15} fontWeight="500" mb={2}>Key Responsibilities:</Text>
                        <VStack space={2}>
                          {experience.key_responsibilities?.map((responsibility, idx) => (
                            <Text key={idx} fontSize={14} pl={3}>• {responsibility}</Text>
                          ))}
                        </VStack>
                      </Box>
                    ))}
                  </VStack>
                </Box>
                {/* Traits Analysis Section */}
                <Box bg="$backgroundLight50" p={4} borderRadius={8} mb={6}>
                  <Text fontSize={18} fontWeight="600" color="#1B3444" mb={3}>Traits Analysis</Text>
                  <VStack space={3}>
                    {ParserAnalysis.personality_traits.traits?.map((trait, index) => (
                      <Box
                        key={index}
                        bg="white"
                        p={3}
                        borderRadius={6}
                        borderWidth={1}
                        borderColor="$borderLight200"
                      >
                        <Text fontSize={16} fontWeight="500" mb={1}>{trait.trait}</Text>
                        <Text fontSize={14} color="$textLight600">Evidence: {trait.evidence}</Text>
                      </Box>
                    ))}
                  </VStack>
                </Box>

              
              </Box>
            ) : (
              <Text fontFamily="$fontContent">No analysis data available</Text>
            )}
          </VStack>
        </ScrollView>
        </Box>
     
    </Motion.View>
  );
} 