import {
	Box,
	FlatList,
	Text,
	HStack,
	TrashIcon,
	Select,
} from '@gluestack-ui/themed'
import { Pressable, View } from 'react-native'
import React, { useState, useCallback, useEffect } from 'react'
import { API, Storage } from 'aws-amplify'
import { useDropzone } from 'react-dropzone'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import { MobileHeader } from '../../newComponents/header/Header'
import ButtonComponent from '../../newComponents/ButtonComponent'
import { createResumeUploadForPreference, updateResumeUploadForPreference, deleteResumeUploadForPreference, createTalentPoolUploadedResume } from '../../graphql/mutations'
import { listResumeUploadForPreferencebyPreferenceId } from '../../graphql/queries'
import useUserStore from '../../Userstore/UserStore'
import ResumeAnalysisDrawer from '../../newComponents/ResumeAnalysisDrawer'
import ModalComponent from '../../newComponents/ModalComponent'
import LabelWithInputComponent from '../../newComponents/LabelWithInputComponent'
import DropdownComponent from '../../newComponents/DropdownComponent'
const windowWidth = window.innerWidth
const contentWidth = windowWidth * 0.7

const UploadResumeStatusEnum = {
	Called: 'Called',
	NotCalled: 'NotCalled',
	MailedLink: 'MailedLink',
	Notqualified: 'Notqualified'
}
const DropdownExperienceStatus = [
	{ label: 'Called', value: 'Called' },
	{ label: 'Not Called', value: 'NotCalled' },
	{ label: 'Mailed Link', value: 'MailedLink' },
	{ label: 'Not Qualified', value: 'Notqualified' },
]
export default function UploadResumeForPreference({ route, navigation }) {
	const { PreferenceId, JobPostId, JobPostName } = route.params
	const [loading, setLoading] = useState(false)
	const [fetchingResumes, setFetchingResumes] = useState(false)
	const [uploadedResumes, setUploadedResumes] = useState([])
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const [selectedResumeAnalysis, setSelectedResumeAnalysis] = useState(null)
	const userId = useUserStore(state => state.userId)
	const userMailId = useUserStore(state => state.userEmail)
	const [isEditModalOpen, setIsEditModalOpen] = useState(false)
	const [selectedResume, setSelectedResume] = useState(null)
	const [formData, setFormData] = useState({
		currentCTC: '',
		ExpectedCTC: '',
		Remarks: '',
		AvailabilityMode: '',
		AvailabilityLocation: '',
		AvailabilityTime: '',
		NoticePeriod: ''
	})
	const [addingToTalentPool, setAddingToTalentPool] = useState({});
	const [updatingResumeDetails, setUpdatingResumeDetails] = useState(false);
	const [deletingResumes, setDeletingResumes] = useState({});
	
    console.log(PreferenceId)

	const fetchResumes = async () => {
		try {
			setFetchingResumes(true);
			const response = await API.graphql({
				query: listResumeUploadForPreferencebyPreferenceId,
				variables: { PreferenceId }
			});
			const resumes = response.data.listResumeUploadForPreferencebyPreferenceId.items;
			setUploadedResumes(resumes);
		} catch (err) {
			console.error('Error fetching resumes:', err);
		} finally {
			setFetchingResumes(false);
		}
	};

	useEffect(() => {
		fetchResumes();
	}, [PreferenceId]);

	const onDrop = useCallback(async (acceptedFiles) => {
		try {
			setLoading(true)

			for (const file of acceptedFiles) {
				if (!file.type.includes('pdf')) {
					console.error('Only PDF files are allowed')
					continue
				}

				const timestamp = new Date().getTime()
				const key = `${timestamp}_resumeuploadedforpreference_${file.name}`

				// Upload file to S3
				await Storage.put(key, file, {
					contentType: file.type,
				})

				// Create entry in DynamoDB
				const resumeData = {
					PreferenceId: PreferenceId,
					JobPostId: PreferenceId,
					UploadedResumeKey: key,
					Status: 'NotCalled',
					UpdatedByUserId: userId,
					UpdatedByUserMailId: userMailId
                   
				
				}

				const newResume = await API.graphql({
					query: createResumeUploadForPreference,
					variables: { input: resumeData }
				})

				setUploadedResumes(prev => [...prev, newResume.data.createResumeUploadForPreference])
			}

			// After successful upload, fetch updated list
			await fetchResumes();
		} catch (err) {
			console.error(err)
		} finally {
			setLoading(false)
		}
	}, [PreferenceId, PreferenceId])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			'application/pdf': ['.pdf']
		},
		multiple: true
	})

	const handleStatusUpdate = async (id, newStatus) => {
		try {
			const updatedResume = await API.graphql({
				query: updateResumeUploadForPreference,
				variables: {
					input: {
						id,
						Status: newStatus,
						UpdatedByUserId: userId,
						UpdatedByUserMailId: userMailId
					}
				}
			})

			setUploadedResumes(prev => 
				prev.map(resume => 
					resume.id === id ? updatedResume.data.updateResumeUploadForPreference : resume
				)
			)
		} catch (err) {
			console.error(err)
		}
	}

	const handleUpdateResumeDetails = async () => {
		try {
			setUpdatingResumeDetails(true);
			const updatedResume = await API.graphql({
				query: updateResumeUploadForPreference,
				variables: {
					input: {
						id: selectedResume.id,
						currentCTC: formData.currentCTC,
						ExpectedCTC: formData.ExpectedCTC,
						Remarks: formData.Remarks,
						AvailabilityMode: formData.AvailabilityMode,
						AvailabilityLocation: formData.AvailabilityLocation,
						AvailabilityTime: formData.AvailabilityTime,
						NoticePeriod: formData.NoticePeriod,
						UpdatedByUserId: userId,
						UpdatedByUserMailId: userMailId
					}
				}
			});

			setUploadedResumes(prev => 
				prev.map(resume => 
					resume.id === selectedResume.id ? updatedResume.data.updateResumeUploadForPreference : resume
				)
			);
			setIsEditModalOpen(false);
		} catch (err) {
			console.error('Error updating resume details:', err);
		} finally {
			setUpdatingResumeDetails(false);
		}
	};

	const renderResumeItem = ({ item }) => {
		
		const handleDownload = async () => {
			try {
				// Get the signed URL for the file
				const signedURL = await Storage.get(item.UploadedResumeKey);
				
				// Create a temporary anchor element to trigger the download
				const link = document.createElement('a');
				link.href = signedURL;
				link.download = item.UploadedResumeKey.split('_').pop(); // Extract original filename
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} catch (err) {
				console.error('Error downloading file:', err);
			}
		};

		const handleDelete = async () => {
			try {
				setDeletingResumes(prev => ({ ...prev, [item.id]: true }));
				
				// Delete from DynamoDB
				await API.graphql({
					query: deleteResumeUploadForPreference,
					variables: { input: { id: item.id } }
				});

				// Delete from S3
				await Storage.remove(item.UploadedResumeKey);

				// Update local state to remove the deleted resume
				setUploadedResumes(prev => prev.filter(resume => resume.id !== item.id));
			} catch (err) {
				console.error('Error deleting resume:', err);
			} finally {
				setDeletingResumes(prev => ({ ...prev, [item.id]: false }));
			}
		};

		const handleAddToTalentPool = async () => {
			try {
				setAddingToTalentPool(prev => ({ ...prev, [item.id]: true }));
				
				// Create entry in TalentPool
				const talentPoolData = {
					PreferenceId: PreferenceId,
					JobPostId: JobPostId,
					UploadedResumeKey: item.UploadedResumeKey,
					ResumeUploadForPreferenceId: item.id,
					ResumeAnalysis: item.ResumeAnalysisforPreferecnes || null,
					NoticePeriod: item.NoticePeriod || '',
					currentCTC: item.currentCTC || '',
					ExpectedCTC: item.ExpectedCTC || '',
					AvailabilityTime: item.AvailabilityTime || '',
					AvailabilityLocation: item.AvailabilityLocation || '',
					AvailabilityMode: item.AvailabilityMode || '',
					Remarks: item.Remarks || '',
					UpdatedByUserId: item.UpdatedByUserId,
					PushedByUserId: userId,
					PushedByUserMailId: userMailId
				};

				await API.graphql({
					query: createTalentPoolUploadedResume,
					variables: { input: talentPoolData }
				});

				const updatedResume = await API.graphql({
					query: updateResumeUploadForPreference,
					variables: {
						input: {
							id: item.id,
							IntalentPoolUploadedResume: true,
						}
					}
				});

				setUploadedResumes(prev => 
					prev.map(resume => 
						resume.id === item.id ? updatedResume.data.updateResumeUploadForPreference : resume
					)
				);
			} catch (err) {
				console.error('Error adding to talent pool:', err);
			} finally {
				setAddingToTalentPool(prev => ({ ...prev, [item.id]: false }));
			}
		};

		return (
			<Box p={4} borderWidth={1} borderRadius={8} mb={2} marginBottom={20}>
				<HStack justifyContent="space-between" alignItems="center">
					<Text>Resume: {item.UploadedResumeKey.split('_').pop()}</Text>
					<HStack space={2}>
						{item.ResumeAnalysisforPreferecnes && (
							<ButtonComponent
								text="View Analysis"
								onPress={() => {
									setSelectedResumeAnalysis(item.ResumeAnalysisforPreferecnes);
									setIsDrawerOpen(true);
								}}
								marginHorizontal={10}
								width={150}
								height={30}
								backgroundColor="$primary500"
								color="$primary500"
								fontWeight="600"
								
							/>
						)}
						<ButtonComponent
							text="Download"
							onPress={handleDownload}
							width={150}
							height={30}
							marginHorizontal={10}
							backgroundColor="$primary500"
								color="$primary500"
								fontWeight="600"
							
						/>
						
						<ButtonComponent
							text={item.IntalentPoolUploadedResume ? "In Talent Pool" : addingToTalentPool[item.id] ? "Adding..." : "Add to Talent Pool"}
							onPress={handleAddToTalentPool}
							width={250}
							height={30}
							marginHorizontal={10}
								color="$primary500"
								fontWeight="600"
							backgroundColor={item.IntalentPoolUploadedResume ? "$gray400" : "$primary500"}
							disabled={item.IntalentPoolUploadedResume || addingToTalentPool[item.id]}
						/>
						<ButtonComponent
							text="Edit Details"
							onPress={() => {
								setSelectedResume(item);
								setFormData({
									currentCTC: item.currentCTC || '',
									ExpectedCTC: item.ExpectedCTC || '',
									Remarks: item.Remarks || '',
									AvailabilityMode: item.AvailabilityMode || '',
									AvailabilityLocation: item.AvailabilityLocation || '',
									AvailabilityTime: item.AvailabilityTime || '',
									NoticePeriod: item.NoticePeriod || ''
								});
								setIsEditModalOpen(true);
							}}
							width={150}
							height={30}
							marginHorizontal={10}
							backgroundColor="$primary500"
							ma
						/>
					
						<Box 
							cursor="pointer" 
							
							p="$2"
							borderRadius="$md"
							
							
						>
							<Pressable 
								onPress={handleDelete}
								disabled={deletingResumes[item.id]}
							>
								{deletingResumes[item.id] ? (
									<SpinnerComponent size="small" />
								) : (
									<TrashIcon 
										size="md" 
										color="$red500"
									/>
								)}
							</Pressable>
							
						</Box>
					</HStack>
				</HStack>
				
				<Text>Status: {item.Status}</Text>
				<Text>Uploaded: {new Date(item.createdAt).toLocaleString()}</Text>
				{item.UpdatedByUserMailId && (
					<Text>Last Updated By: {item.UpdatedByUserMailId}</Text>
				)}
				{item.IntalentPoolUploadedResume && (
					<Text color="$success700" mt={1}>✓ This resume is in the talent pool</Text>
				)}
				
				<Box mt={2}>
					<DropdownComponent
						placeholder="Choose Status"
						userpreferences={{ status: item.Status }}
						setUserPreferences={(newValue) => handleStatusUpdate(item.id, newValue.status)}
						property="status"
						data={DropdownExperienceStatus}
						width={200}
					/>
				</Box>
			</Box>
		)
	}

	return (
		<View style={{ flex: 1, alignItems: 'center' }}>
			<Box width="100%" zIndex={1} marginTop={30}>
				<MobileHeader props={'Upload Resumes'} navigation={navigation} />
			</Box>
		
			<Box width={contentWidth} maxWidth={contentWidth} style={{ marginTop: 50 }}>
			<Text
												fontFamily="$fontHeading"
												fontWeight="900"
												fontSize={
													windowWidth > 480 ? 25 : 18
												}
												color={'$primary950'}
												lineHeight={
													windowWidth > 480 ? 30 : 20
												}
												marginBottom={20}
											>
												
													{JobPostName}
			
		</Text>
				<Box
					{...getRootProps()}
					borderWidth={2}
					borderStyle="dashed"
					borderRadius={8}
					p={4}
					mb={4}
					alignItems="center"
					bg={isDragActive ? '$primary100' : 'transparent'}
				>
					<input {...getInputProps()} />
					{loading ? (
						<SpinnerComponent />
					) : (
						<>
							<Text fontSize={16} mb={2}>
								{isDragActive
									? 'Drop the files here...'
									: 'Drag & drop PDF resumes here, or click to select files'}
							</Text>
							<Text fontSize={14} color="$textLight500">
								Only PDF files are accepted
							</Text>
						</>
					)}
				</Box>
				
				{fetchingResumes ? (
					<SpinnerComponent />
				) : uploadedResumes.length === 0 ? (
					<Box p={4} alignItems="center">
						<Text fontSize={16} color="$textLight500">
							No resumes uploaded yet
						</Text>
					</Box>
				) : (
					<FlatList
						data={uploadedResumes}
						renderItem={renderResumeItem}
						keyExtractor={item => item.id}
						contentContainerStyle={{ paddingTop: 20, paddingBottom: 20 }}
					/>
				)}
			</Box>
			
			<ResumeAnalysisDrawer
				isOpen={isDrawerOpen}
				onClose={() => setIsDrawerOpen(false)}
				analysisData={selectedResumeAnalysis}
			/>
			<ModalComponent
				isOpen={isEditModalOpen}
				onClose={() => setIsEditModalOpen(false)}
				heading="Update Resume Details"
				renderBody={() => (
					<Box p={4} width= "100%" paddingBottom={40} paddingHorizontal={20}>
						<LabelWithInputComponent
							label="Current CTC"
							
							placeholder="Enter Current CTC"
							value={formData.currentCTC}
							setValueChange={(text) => setFormData(prev => ({ ...prev, currentCTC: text }))}
						/>
						<LabelWithInputComponent
							label="Expected CTC"
							placeholder="Enter Expected CTC"
							value={formData.ExpectedCTC}
							setValueChange={(text) => setFormData(prev => ({ ...prev, ExpectedCTC: text }))}
						/>
						<LabelWithInputComponent
							label="Notice Period"
							placeholder="Enter Notice Period"
							value={formData.NoticePeriod}
							setValueChange={(text) => setFormData(prev => ({ ...prev, NoticePeriod: text }))}
						/>
						<LabelWithInputComponent
							label="Availability Mode"
							placeholder="Enter Availability Mode"
							value={formData.AvailabilityMode}
							setValueChange={(text) => setFormData(prev => ({ ...prev, AvailabilityMode: text }))}
						/>
						<LabelWithInputComponent
							label="Availability Location"
							placeholder="Enter Availability Location"
							value={formData.AvailabilityLocation}
							setValueChange={(text) => setFormData(prev => ({ ...prev, AvailabilityLocation: text }))}
						/>
						<LabelWithInputComponent
							label="Availability Time"
							placeholder="Enter Availability Time"
							value={formData.AvailabilityTime}
							setValueChange={(text) => setFormData(prev => ({ ...prev, AvailabilityTime: text }))}
						/>
						<LabelWithInputComponent
							label="Remarks"
							placeholder="Enter Remarks"
							value={formData.Remarks}
							heightTextArea={170}
							height={170}
							numberOfLines={4}
							multiline={true}
							
							setValueChange={(text) => setFormData(prev => ({ ...prev, Remarks: text }))}
						/>
						<ButtonComponent
							text={updatingResumeDetails ? "Updating..." : "Update"}
							onPress={handleUpdateResumeDetails}
							width={100}
							height={30}
							backgroundColor="$primary500"
							marginTop={20}
							disabled={updatingResumeDetails}
						/>
					</Box>
				)}
			/>
		</View>
	)
}
