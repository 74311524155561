/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const ResumeParser = /* GraphQL */ `
  query ResumeParser($msg: String) {
    ResumeParser(msg: $msg)
  }
`;
export const SendEmailtoCandidates = /* GraphQL */ `
  query SendEmailtoCandidates(
    $EmailBody: String
    $Emails: String
    $EmailSubject: String
    $Link: String
    $Question: String
    $Answer: String
    $EmployerName: String
    $CandidateName: String
    $Jobtitle: String
    $QrewName: String
    $QrewBillno: String
    $PLatformBillno: String
    $TypeOfMailenum: TypeOfMailenum
  ) {
    SendEmailtoCandidates(
      EmailBody: $EmailBody
      Emails: $Emails
      EmailSubject: $EmailSubject
      Link: $Link
      Question: $Question
      Answer: $Answer
      EmployerName: $EmployerName
      CandidateName: $CandidateName
      Jobtitle: $Jobtitle
      QrewName: $QrewName
      QrewBillno: $QrewBillno
      PLatformBillno: $PLatformBillno
      TypeOfMailenum: $TypeOfMailenum
    )
  }
`;
export const ProfileAnalysis = /* GraphQL */ `
  query ProfileAnalysis(
    $JobPostid: String
    $profileid: String
    $JobApplicationId: String
    $Flag: String
  ) {
    ProfileAnalysis(
      JobPostid: $JobPostid
      profileid: $profileid
      JobApplicationId: $JobApplicationId
      Flag: $Flag
    )
  }
`;
export const getTalentPool = /* GraphQL */ `
  query GetTalentPool($id: ID!) {
    getTalentPool(id: $id) {
      id
      CandidateProfileId
      CandidateUserId
      CandidateProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      Domain
      CandidateEngagementType
      createdAt
      updatedAt
      Availability
      NoticePeriod
      AvailabilityTime
      AvailabilityLocation
      AvailabilityMode
      Skills
      TotalExperience
      Remarks
      owner
      __typename
    }
  }
`;
export const listTalentPools = /* GraphQL */ `
  query ListTalentPools(
    $filter: ModelTalentPoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTalentPools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        CandidateProfileId
        CandidateUserId
        CandidateProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        Domain
        CandidateEngagementType
        createdAt
        updatedAt
        Availability
        NoticePeriod
        AvailabilityTime
        AvailabilityLocation
        AvailabilityMode
        Skills
        TotalExperience
        Remarks
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHIDI = /* GraphQL */ `
  query GetHIDI($id: ID!) {
    getHIDI(id: $id) {
      createdAt
      Email
      id
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      Talents {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          Talent
          Level
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signals {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          signalname
          score
          signalcategory
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      MobileNumber
      name
      UserId
      PSStitle
      PSSdescription
      DMStitle
      DMSdescription
      CTtitle
      CTdescription
      Atitle
      Adescription
      Verified
      updatedAt
      owner
      __typename
    }
  }
`;
export const listHIDIS = /* GraphQL */ `
  query ListHIDIS(
    $filter: ModelHIDIFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHIDIS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listhidisByUserIdByDate = /* GraphQL */ `
  query ListhidisByUserIdByDate(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHIDIFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listhidisByUserIdByDate(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
      hidis {
        items {
          createdAt
          Email
          id
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          MobileNumber
          name
          UserId
          PSStitle
          PSSdescription
          DMStitle
          DMSdescription
          CTtitle
          CTdescription
          Atitle
          Adescription
          Verified
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Skills {
        items {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Talents {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          Talent
          Level
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signals {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          signalname
          score
          signalcategory
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      resume
      education
      experience
      expertise
      graduation
      iframescorelink
      iframehidilink
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfileByUserId = /* GraphQL */ `
  query GetProfileByUserId(
    $UserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProfileByUserId(
      UserId: $UserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewprofile = /* GraphQL */ `
  query GetNewprofile($id: ID!) {
    getNewprofile(id: $id) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
      hidis {
        items {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidateEducation {
        items {
          createdAt
          UserId
          id
          degree
          institute
          duration
          startDate
          EndDate
          Present
          percentage
          description
          location
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedforqrews {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              PaymentCurrency
              HourlyRate
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          PaymentCurrency
          HourlyRate
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          ContractPausedDate
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidateExperience {
        items {
          createdAt
          UserId
          id
          startDate
          EndDate
          Present
          company
          designation
          description
          duration
          location
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      comapanyshortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      comapanyrejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      inpreference {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Skills {
        items {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      profileFeedback {
        items {
          createdAt
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          designation
          description
          UserId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      certifications {
        items {
          createdAt
          id
          Certificatename
          description
          Source
          certificateId
          validity
          UserId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Capabilities {
        items {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidatePreferences {
        items {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ActivatedCandisatePreferencesID
      activeCandidatePreferences {
        createdAt
        id
        rolename
        jobType
        workType
        rolelevel
        benefits
        priority
        expectation
        Salaryexpectation
        ContractCompensation
        HourlyRateExpectedOnContract
        currentSalary
        companyType
        workStyle
        minimumContractPeriod
        servingNotice
        noticePeriod
        workSchedule
        Activelylooking
        contractType
        compensationStructure
        AvailableDate
        userDomain
        roletype
        location1
        location2
        location3
        Country
        CurrencyCountry
        ActivatePreference
        prevContractCount
        profileId
        UserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ProfilebyCandidatePreferencesId {
          items {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      ConnectedUserSegments {
        items {
          UserSegmentId
          UserId
          createdAt
          updatedAt
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      salarycurrent
      InTalentPoolBoolean
      InTalentPool {
        id
        CandidateProfileId
        CandidateUserId
        CandidateProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        Domain
        CandidateEngagementType
        createdAt
        updatedAt
        Availability
        NoticePeriod
        AvailabilityTime
        AvailabilityLocation
        AvailabilityMode
        Skills
        TotalExperience
        Remarks
        owner
        __typename
      }
      salaryexpectation
      certification
      noticeperiod
      currentoffer
      education
      experience
      expertise
      graduation
      image
      resume
      location
      imageicon1
      link1
      imageicon2
      link2
      imageicon3
      link3
      profileviews
      relevant
      connected
      imagekey
      typeofwork
      resumekey
      ConsentToShareInfo
      IntroVideo
      updatedAt
      owner
      __typename
    }
  }
`;
export const listNewprofiles = /* GraphQL */ `
  query ListNewprofiles(
    $filter: ModelNewprofileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewprofiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewprofileByUserId = /* GraphQL */ `
  query GetNewprofileByUserId(
    $UserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNewprofileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNewprofileByUserId(
      UserId: $UserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActivatedPreferencesId = /* GraphQL */ `
  query GetActivatedPreferencesId(
    $ActivatedCandisatePreferencesID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNewprofileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getActivatedPreferencesId(
      ActivatedCandisatePreferencesID: $ActivatedCandisatePreferencesID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResumeUploadForPreference = /* GraphQL */ `
  query GetResumeUploadForPreference($id: ID!) {
    getResumeUploadForPreference(id: $id) {
      id
      PreferenceId
      JobPostId
      UploadedResumeKey
      createdAt
      updatedAt
      Status
      Availability
      NoticePeriod
      AvailabilityTime
      AvailabilityLocation
      AvailabilityMode
      currentCTC
      ExpectedCTC
      Remarks
      ResumeScroeforPreferecnes
      ResumeAnalysisforPreferecnes
      UpdatedByUserId
      UpdatedByUserMailId
      IntalentPoolUploadedResume
      owner
      __typename
    }
  }
`;
export const listResumeUploadForPreferences = /* GraphQL */ `
  query ListResumeUploadForPreferences(
    $filter: ModelResumeUploadForPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResumeUploadForPreferences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        PreferenceId
        JobPostId
        UploadedResumeKey
        createdAt
        updatedAt
        Status
        Availability
        NoticePeriod
        AvailabilityTime
        AvailabilityLocation
        AvailabilityMode
        currentCTC
        ExpectedCTC
        Remarks
        ResumeScroeforPreferecnes
        ResumeAnalysisforPreferecnes
        UpdatedByUserId
        UpdatedByUserMailId
        IntalentPoolUploadedResume
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listResumeUploadForPreferencebyPreferenceId = /* GraphQL */ `
  query ListResumeUploadForPreferencebyPreferenceId(
    $PreferenceId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResumeUploadForPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResumeUploadForPreferencebyPreferenceId(
      PreferenceId: $PreferenceId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        PreferenceId
        JobPostId
        UploadedResumeKey
        createdAt
        updatedAt
        Status
        Availability
        NoticePeriod
        AvailabilityTime
        AvailabilityLocation
        AvailabilityMode
        currentCTC
        ExpectedCTC
        Remarks
        ResumeScroeforPreferecnes
        ResumeAnalysisforPreferecnes
        UpdatedByUserId
        UpdatedByUserMailId
        IntalentPoolUploadedResume
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTalentPoolUploadedResume = /* GraphQL */ `
  query GetTalentPoolUploadedResume($id: ID!) {
    getTalentPoolUploadedResume(id: $id) {
      id
      ResumeUploadForPreferenceId
      PreferenceId
      ResumeAnalysis
      UploadedResumeKey
      Availability
      NoticePeriod
      currentCTC
      ExpectedCTC
      AvailabilityTime
      AvailabilityLocation
      AvailabilityMode
      Remarks
      ResumeScroeforPreferecnes
      UpdatedByUserId
      ResumeUploadForPreference {
        id
        PreferenceId
        JobPostId
        UploadedResumeKey
        createdAt
        updatedAt
        Status
        Availability
        NoticePeriod
        AvailabilityTime
        AvailabilityLocation
        AvailabilityMode
        currentCTC
        ExpectedCTC
        Remarks
        ResumeScroeforPreferecnes
        ResumeAnalysisforPreferecnes
        UpdatedByUserId
        UpdatedByUserMailId
        IntalentPoolUploadedResume
        owner
        __typename
      }
      PushedByUserId
      PushedByUserMailId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTalentPoolUploadedResumes = /* GraphQL */ `
  query ListTalentPoolUploadedResumes(
    $filter: ModelTalentPoolUploadedResumeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTalentPoolUploadedResumes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ResumeUploadForPreferenceId
        PreferenceId
        ResumeAnalysis
        UploadedResumeKey
        Availability
        NoticePeriod
        currentCTC
        ExpectedCTC
        AvailabilityTime
        AvailabilityLocation
        AvailabilityMode
        Remarks
        ResumeScroeforPreferecnes
        UpdatedByUserId
        ResumeUploadForPreference {
          id
          PreferenceId
          JobPostId
          UploadedResumeKey
          createdAt
          updatedAt
          Status
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          currentCTC
          ExpectedCTC
          Remarks
          ResumeScroeforPreferecnes
          ResumeAnalysisforPreferecnes
          UpdatedByUserId
          UpdatedByUserMailId
          IntalentPoolUploadedResume
          owner
          __typename
        }
        PushedByUserId
        PushedByUserMailId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSavedJobs = /* GraphQL */ `
  query GetSavedJobs($id: ID!) {
    getSavedJobs(id: $id) {
      id
      JobPostId
      ProfileId
      UserId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSavedJobs = /* GraphQL */ `
  query ListSavedJobs(
    $filter: ModelSavedJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSavedJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        JobPostId
        ProfileId
        UserId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSavedJobsbyJobPostId = /* GraphQL */ `
  query ListSavedJobsbyJobPostId(
    $JobPostId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSavedJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSavedJobsbyJobPostId(
      JobPostId: $JobPostId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostId
        ProfileId
        UserId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listsSavedJobPostByUserId = /* GraphQL */ `
  query ListsSavedJobPostByUserId(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSavedJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listsSavedJobPostByUserId(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostId
        ProfileId
        UserId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCandidatePreferences = /* GraphQL */ `
  query GetCandidatePreferences($id: ID!) {
    getCandidatePreferences(id: $id) {
      createdAt
      id
      rolename
      jobType
      workType
      rolelevel
      benefits
      priority
      expectation
      Salaryexpectation
      ContractCompensation
      HourlyRateExpectedOnContract
      currentSalary
      companyType
      workStyle
      minimumContractPeriod
      servingNotice
      noticePeriod
      workSchedule
      Activelylooking
      contractType
      compensationStructure
      AvailableDate
      userDomain
      roletype
      location1
      location2
      location3
      Country
      CurrencyCountry
      ActivatePreference
      prevContractCount
      profileId
      UserProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ProfilebyCandidatePreferencesId {
        items {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCandidatePreferences = /* GraphQL */ `
  query ListCandidatePreferences(
    $filter: ModelCandidatePreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidatePreferences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        rolename
        jobType
        workType
        rolelevel
        benefits
        priority
        expectation
        Salaryexpectation
        ContractCompensation
        HourlyRateExpectedOnContract
        currentSalary
        companyType
        workStyle
        minimumContractPeriod
        servingNotice
        noticePeriod
        workSchedule
        Activelylooking
        contractType
        compensationStructure
        AvailableDate
        userDomain
        roletype
        location1
        location2
        location3
        Country
        CurrencyCountry
        ActivatePreference
        prevContractCount
        profileId
        UserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ProfilebyCandidatePreferencesId {
          items {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listsCandidatePreferencesBycreatedAt = /* GraphQL */ `
  query ListsCandidatePreferencesBycreatedAt(
    $profileId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCandidatePreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listsCandidatePreferencesBycreatedAt(
      profileId: $profileId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        rolename
        jobType
        workType
        rolelevel
        benefits
        priority
        expectation
        Salaryexpectation
        ContractCompensation
        HourlyRateExpectedOnContract
        currentSalary
        companyType
        workStyle
        minimumContractPeriod
        servingNotice
        noticePeriod
        workSchedule
        Activelylooking
        contractType
        compensationStructure
        AvailableDate
        userDomain
        roletype
        location1
        location2
        location3
        Country
        CurrencyCountry
        ActivatePreference
        prevContractCount
        profileId
        UserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ProfilebyCandidatePreferencesId {
          items {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewProfileFeedback = /* GraphQL */ `
  query GetNewProfileFeedback($id: ID!) {
    getNewProfileFeedback(id: $id) {
      createdAt
      id
      profileId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      name
      designation
      description
      UserId
      updatedAt
      owner
      __typename
    }
  }
`;
export const listNewProfileFeedbacks = /* GraphQL */ `
  query ListNewProfileFeedbacks(
    $filter: ModelNewProfileFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewProfileFeedbacks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        designation
        description
        UserId
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listsNewProfileFeedbackIdByprofileId = /* GraphQL */ `
  query ListsNewProfileFeedbackIdByprofileId(
    $profileId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNewProfileFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listsNewProfileFeedbackIdByprofileId(
      profileId: $profileId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        designation
        description
        UserId
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listsNewProfileFeedbackIdByUserId = /* GraphQL */ `
  query ListsNewProfileFeedbackIdByUserId(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNewProfileFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listsNewProfileFeedbackIdByUserId(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        designation
        description
        UserId
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCertifications = /* GraphQL */ `
  query GetCertifications($id: ID!) {
    getCertifications(id: $id) {
      createdAt
      id
      Certificatename
      description
      Source
      certificateId
      validity
      UserId
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCertifications = /* GraphQL */ `
  query ListCertifications(
    $filter: ModelCertificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        Certificatename
        description
        Source
        certificateId
        validity
        UserId
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listsCerticationsByUserId = /* GraphQL */ `
  query ListsCerticationsByUserId(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCertificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listsCerticationsByUserId(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        Certificatename
        description
        Source
        certificateId
        validity
        UserId
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewhidi = /* GraphQL */ `
  query GetNewhidi($id: ID!) {
    getNewhidi(id: $id) {
      createdAt
      Email
      id
      profileId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      name
      UserId
      challenges {
        items {
          createdAt
          id
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          challenge
          HidiId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      outcomes {
        items {
          createdAt
          id
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          Outcome
          HidiId
          Verified
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConnectedCapability {
        items {
          CapabilityId
          HidiId
          Capability {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          createdAt
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connectedFeeds {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      summary
      description
      title
      Verified
      likes
      views
      steps
      jobs
      outcomelink
      impact
      impactOverall
      imagelink
      outcome
      pinnedFlag
      tag1
      tag2
      htmlheading
      htmlsummary
      htmloutput
      peerEmails
      helped
      problem
      Hack
      experience
      projectLink
      whereitwasdone
      whereitwasdonecompany
      MeatFoundbyAI
      MeatFoundbyHuman
      MeatWeightagebyHuman
      AiAnalysisForMeat
      SkillUsed
      ExperinceInSkill
      outcomejson
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      AiGenerated
      ProbabilityFake
      ProbabilityReal
      hidiTopic
      updatedAt
      owner
      __typename
    }
  }
`;
export const listNewhidis = /* GraphQL */ `
  query ListNewhidis(
    $filter: ModelNewhidiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewhidis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        MeatFoundbyAI
        MeatFoundbyHuman
        MeatWeightagebyHuman
        AiAnalysisForMeat
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listNewhidisByprofileIdByDate = /* GraphQL */ `
  query ListNewhidisByprofileIdByDate(
    $profileId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNewhidiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewhidisByprofileIdByDate(
      profileId: $profileId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        MeatFoundbyAI
        MeatFoundbyHuman
        MeatWeightagebyHuman
        AiAnalysisForMeat
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listNewhidisByUserIdByDate = /* GraphQL */ `
  query ListNewhidisByUserIdByDate(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNewhidiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewhidisByUserIdByDate(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        MeatFoundbyAI
        MeatFoundbyHuman
        MeatWeightagebyHuman
        AiAnalysisForMeat
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listNewhidisByhidiTopic = /* GraphQL */ `
  query ListNewhidisByhidiTopic(
    $hidiTopic: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNewhidiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewhidisByhidiTopic(
      hidiTopic: $hidiTopic
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        MeatFoundbyAI
        MeatFoundbyHuman
        MeatWeightagebyHuman
        AiAnalysisForMeat
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConvoOnHidi = /* GraphQL */ `
  query GetConvoOnHidi($id: ID!) {
    getConvoOnHidi(id: $id) {
      id
      Question
      Answer
      profileId
      UserId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      PreferenceId
      companyProfileId
      CompanyProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      companyUserProfileId
      HidiId
      NewHidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        MeatFoundbyAI
        MeatFoundbyHuman
        MeatWeightagebyHuman
        AiAnalysisForMeat
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      companyUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        UserRole
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listConvoOnHidis = /* GraphQL */ `
  query ListConvoOnHidis(
    $filter: ModelConvoOnHidiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConvoOnHidis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Question
        Answer
        profileId
        UserId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        PreferenceId
        companyProfileId
        CompanyProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        companyUserProfileId
        HidiId
        NewHidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        companyUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const convoOnHidisByProfileId = /* GraphQL */ `
  query ConvoOnHidisByProfileId(
    $profileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConvoOnHidiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    convoOnHidisByProfileId(
      profileId: $profileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Question
        Answer
        profileId
        UserId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        PreferenceId
        companyProfileId
        CompanyProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        companyUserProfileId
        HidiId
        NewHidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        companyUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const convoOnHidisByPreferenceId = /* GraphQL */ `
  query ConvoOnHidisByPreferenceId(
    $PreferenceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConvoOnHidiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    convoOnHidisByPreferenceId(
      PreferenceId: $PreferenceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Question
        Answer
        profileId
        UserId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        PreferenceId
        companyProfileId
        CompanyProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        companyUserProfileId
        HidiId
        NewHidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        companyUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const convoOnHidisByCompanyProfileId = /* GraphQL */ `
  query ConvoOnHidisByCompanyProfileId(
    $companyProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConvoOnHidiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    convoOnHidisByCompanyProfileId(
      companyProfileId: $companyProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Question
        Answer
        profileId
        UserId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        PreferenceId
        companyProfileId
        CompanyProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        companyUserProfileId
        HidiId
        NewHidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        companyUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const convoOnHidisByHidiId = /* GraphQL */ `
  query ConvoOnHidisByHidiId(
    $HidiId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConvoOnHidiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    convoOnHidisByHidiId(
      HidiId: $HidiId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Question
        Answer
        profileId
        UserId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        PreferenceId
        companyProfileId
        CompanyProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        companyUserProfileId
        HidiId
        NewHidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        companyUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOutcome = /* GraphQL */ `
  query GetOutcome($id: ID!) {
    getOutcome(id: $id) {
      createdAt
      id
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        MeatFoundbyAI
        MeatFoundbyHuman
        MeatWeightagebyHuman
        AiAnalysisForMeat
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      Outcome
      HidiId
      Verified
      updatedAt
      owner
      __typename
    }
  }
`;
export const listOutcomes = /* GraphQL */ `
  query ListOutcomes(
    $filter: ModelOutcomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOutcomes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        Hidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        Outcome
        HidiId
        Verified
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listOutcomebyhidiId = /* GraphQL */ `
  query ListOutcomebyhidiId(
    $HidiId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOutcomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOutcomebyhidiId(
      HidiId: $HidiId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        Hidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        Outcome
        HidiId
        Verified
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChallenges = /* GraphQL */ `
  query GetChallenges($id: ID!) {
    getChallenges(id: $id) {
      createdAt
      id
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        MeatFoundbyAI
        MeatFoundbyHuman
        MeatWeightagebyHuman
        AiAnalysisForMeat
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      challenge
      HidiId
      updatedAt
      owner
      __typename
    }
  }
`;
export const listChallenges = /* GraphQL */ `
  query ListChallenges(
    $filter: ModelChallengesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallenges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        Hidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        challenge
        HidiId
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listChallengesbyhidiId = /* GraphQL */ `
  query ListChallengesbyhidiId(
    $HidiId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChallengesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallengesbyhidiId(
      HidiId: $HidiId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        Hidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        challenge
        HidiId
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTimeline = /* GraphQL */ `
  query GetTimeline($id: ID!) {
    getTimeline(id: $id) {
      createdAt
      UserId
      profileId
      description
      id
      Timelinestring
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTimelines = /* GraphQL */ `
  query ListTimelines(
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        UserId
        profileId
        description
        id
        Timelinestring
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const timelineByUserId = /* GraphQL */ `
  query TimelineByUserId(
    $UserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModeltimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timelineByUserId(
      UserId: $UserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        UserId
        profileId
        description
        id
        Timelinestring
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const timelineByProfileId = /* GraphQL */ `
  query TimelineByProfileId(
    $profileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModeltimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timelineByProfileId(
      profileId: $profileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        UserId
        profileId
        description
        id
        Timelinestring
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCandidateExperience = /* GraphQL */ `
  query GetCandidateExperience($id: ID!) {
    getCandidateExperience(id: $id) {
      createdAt
      UserId
      id
      startDate
      EndDate
      Present
      company
      designation
      description
      duration
      location
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCandidateExperiences = /* GraphQL */ `
  query ListCandidateExperiences(
    $filter: ModelCandidateExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidateExperiences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        UserId
        id
        startDate
        EndDate
        Present
        company
        designation
        description
        duration
        location
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCandidateExperienceByUserIdByDate = /* GraphQL */ `
  query ListCandidateExperienceByUserIdByDate(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCandidateExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidateExperienceByUserIdByDate(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        UserId
        id
        startDate
        EndDate
        Present
        company
        designation
        description
        duration
        location
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCandidateEducation = /* GraphQL */ `
  query GetCandidateEducation($id: ID!) {
    getCandidateEducation(id: $id) {
      createdAt
      UserId
      id
      degree
      institute
      duration
      startDate
      EndDate
      Present
      percentage
      description
      location
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCandidateEducations = /* GraphQL */ `
  query ListCandidateEducations(
    $filter: ModelCandidateEducationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidateEducations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        UserId
        id
        degree
        institute
        duration
        startDate
        EndDate
        Present
        percentage
        description
        location
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCandidateEducationByUserIdByDate = /* GraphQL */ `
  query ListCandidateEducationByUserIdByDate(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCandidateEducationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidateEducationByUserIdByDate(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        UserId
        id
        degree
        institute
        duration
        startDate
        EndDate
        Present
        percentage
        description
        location
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyprofile = /* GraphQL */ `
  query GetCompanyprofile($id: ID!) {
    getCompanyprofile(id: $id) {
      createdAt
      businessUserId
      name
      companyMailId
      id
      preferences
      about
      websiteUrl
      LogoImageKey
      IndustryType
      Shortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CompanyQrew {
        items {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      prefferedprofile {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      preferenceslist {
        items {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyusers {
        items {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCompanyprofiles = /* GraphQL */ `
  query ListCompanyprofiles(
    $filter: ModelCompanyprofileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyprofiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getcompanyprofilebyBusinessMaterUserId = /* GraphQL */ `
  query GetcompanyprofilebyBusinessMaterUserId(
    $businessUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelcompanyprofileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getcompanyprofilebyBusinessMaterUserId(
      businessUserId: $businessUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyusers = /* GraphQL */ `
  query GetCompanyusers($id: ID!) {
    getCompanyusers(id: $id) {
      createdAt
      username
      designation
      ProfilePictureKey
      businessProfileId
      id
      cognitoId
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Shortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      editObjectiveHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      email
      UserRole
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCompanyusers = /* GraphQL */ `
  query ListCompanyusers(
    $filter: ModelCompanyusersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyusers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        UserRole
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getcompanyusersbybusinessProfileId = /* GraphQL */ `
  query GetcompanyusersbybusinessProfileId(
    $businessProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelcompanyusersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getcompanyusersbybusinessProfileId(
      businessProfileId: $businessProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        UserRole
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getcompanyusersbycognitoId = /* GraphQL */ `
  query GetcompanyusersbycognitoId(
    $cognitoId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelcompanyusersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getcompanyusersbycognitoId(
      cognitoId: $cognitoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        UserRole
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyQrew = /* GraphQL */ `
  query GetCompanyQrew($id: ID!) {
    getCompanyQrew(id: $id) {
      id
      qrewname
      aboutqrew
      businessProfileId
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Invoices {
        items {
          id
          CtcInvoiceNumber
          PlatformInvoiceNumber
          CompanyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          businessProfileId
          companyName
          CtcInvoiceS3Key
          PlarformInvoiceS3Key
          CtcTxnNumber
          PlatformTxnNumber
          CtcTxnNumberApproved
          PlatformTxnNumberApproved
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      qrewroles {
        items {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrew {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              PaymentCurrency
              HourlyRate
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          PaymentCurrency
          HourlyRate
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          ContractPausedDate
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      objectives {
        items {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            targetPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      durationInmonths
      qrewLive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCompanyQrews = /* GraphQL */ `
  query ListCompanyQrews(
    $filter: ModelCompanyQrewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyQrews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const companyQrewsByBusinessProfileId = /* GraphQL */ `
  query CompanyQrewsByBusinessProfileId(
    $businessProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyQrewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyQrewsByBusinessProfileId(
      businessProfileId: $businessProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyQrewInvoices = /* GraphQL */ `
  query GetCompanyQrewInvoices($id: ID!) {
    getCompanyQrewInvoices(id: $id) {
      id
      CtcInvoiceNumber
      PlatformInvoiceNumber
      CompanyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      businessProfileId
      companyName
      CtcInvoiceS3Key
      PlarformInvoiceS3Key
      CtcTxnNumber
      PlatformTxnNumber
      CtcTxnNumberApproved
      PlatformTxnNumberApproved
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCompanyQrewInvoices = /* GraphQL */ `
  query ListCompanyQrewInvoices(
    $filter: ModelCompanyQrewInvoicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyQrewInvoices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CtcInvoiceNumber
        PlatformInvoiceNumber
        CompanyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        businessProfileId
        companyName
        CtcInvoiceS3Key
        PlarformInvoiceS3Key
        CtcTxnNumber
        PlatformTxnNumber
        CtcTxnNumberApproved
        PlatformTxnNumberApproved
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompnayQrewInvoicebyCtcInvoiceNumber = /* GraphQL */ `
  query GetCompnayQrewInvoicebyCtcInvoiceNumber(
    $CtcInvoiceNumber: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyQrewInvoicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCompnayQrewInvoicebyCtcInvoiceNumber(
      CtcInvoiceNumber: $CtcInvoiceNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CtcInvoiceNumber
        PlatformInvoiceNumber
        CompanyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        businessProfileId
        companyName
        CtcInvoiceS3Key
        PlarformInvoiceS3Key
        CtcTxnNumber
        PlatformTxnNumber
        CtcTxnNumberApproved
        PlatformTxnNumberApproved
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompnayQrewInvoicebyPlatformInvoiceNumber = /* GraphQL */ `
  query GetCompnayQrewInvoicebyPlatformInvoiceNumber(
    $PlatformInvoiceNumber: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyQrewInvoicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCompnayQrewInvoicebyPlatformInvoiceNumber(
      PlatformInvoiceNumber: $PlatformInvoiceNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CtcInvoiceNumber
        PlatformInvoiceNumber
        CompanyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        businessProfileId
        companyName
        CtcInvoiceS3Key
        PlarformInvoiceS3Key
        CtcTxnNumber
        PlatformTxnNumber
        CtcTxnNumberApproved
        PlatformTxnNumberApproved
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ListCompanyQrewInvoicesbyCompanyQrewId = /* GraphQL */ `
  query ListCompanyQrewInvoicesbyCompanyQrewId(
    $CompanyQrewId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyQrewInvoicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListCompanyQrewInvoicesbyCompanyQrewId(
      CompanyQrewId: $CompanyQrewId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CtcInvoiceNumber
        PlatformInvoiceNumber
        CompanyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        businessProfileId
        companyName
        CtcInvoiceS3Key
        PlarformInvoiceS3Key
        CtcTxnNumber
        PlatformTxnNumber
        CtcTxnNumberApproved
        PlatformTxnNumberApproved
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ListCompanyQrewInvoicesbybusinessProfileId = /* GraphQL */ `
  query ListCompanyQrewInvoicesbybusinessProfileId(
    $businessProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyQrewInvoicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListCompanyQrewInvoicesbybusinessProfileId(
      businessProfileId: $businessProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CtcInvoiceNumber
        PlatformInvoiceNumber
        CompanyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        businessProfileId
        companyName
        CtcInvoiceS3Key
        PlarformInvoiceS3Key
        CtcTxnNumber
        PlatformTxnNumber
        CtcTxnNumberApproved
        PlatformTxnNumberApproved
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPreferences = /* GraphQL */ `
  query GetPreferences($id: ID!) {
    getPreferences(id: $id) {
      createdAt
      id
      rolename
      businessUserId
      businessProfileId
      companyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      recommendedprofiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shortlistedProfiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejectedProfiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobPosts {
        items {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrew {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              PaymentCurrency
              HourlyRate
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          PaymentCurrency
          HourlyRate
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          ContractPausedDate
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobTraits {
        items {
          id
          TraitName
          createdAt
          updatedAt
          preferencesID
          owner
          __typename
        }
        nextToken
        __typename
      }
      package
      certifications
      noticeperiod
      maxExperienceRequired
      minExperienceRequired
      modeofwork
      JobDomain
      CandidateEngagementType
      other
      previousrole
      combinedrole
      currencyCountry
      JobType
      Location
      JobPublished
      numberOfCandidateRequired
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPreferences = /* GraphQL */ `
  query ListPreferences(
    $filter: ModelPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreferences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        CandidateEngagementType
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listsPreferencesByUserIdByDate = /* GraphQL */ `
  query ListsPreferencesByUserIdByDate(
    $businessUserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelpreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listsPreferencesByUserIdByDate(
      businessUserId: $businessUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        CandidateEngagementType
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listsPreferencesByBusinessProfileIdIdByDate = /* GraphQL */ `
  query ListsPreferencesByBusinessProfileIdIdByDate(
    $businessProfileId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelpreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listsPreferencesByBusinessProfileIdIdByDate(
      businessProfileId: $businessProfileId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        CandidateEngagementType
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listPreferencesbyCompanyQrewId = /* GraphQL */ `
  query ListPreferencesbyCompanyQrewId(
    $companyQrewId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelpreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreferencesbyCompanyQrewId(
      companyQrewId: $companyQrewId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        CandidateEngagementType
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobPostQuestions = /* GraphQL */ `
  query GetJobPostQuestions($id: ID!) {
    getJobPostQuestions(id: $id) {
      id
      JobPostId
      preferencesID
      Question
      TypeOfQuesiton
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listJobPostQuestions = /* GraphQL */ `
  query ListJobPostQuestions(
    $filter: ModelJobPostQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobPostQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostId
        preferencesID
        Question
        TypeOfQuesiton
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listJobPostQuestionsbyJobPostId = /* GraphQL */ `
  query ListJobPostQuestionsbyJobPostId(
    $JobPostId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobPostQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobPostQuestionsbyJobPostId(
      JobPostId: $JobPostId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostId
        preferencesID
        Question
        TypeOfQuesiton
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobPostQuestionsAnswered = /* GraphQL */ `
  query GetJobPostQuestionsAnswered($id: ID!) {
    getJobPostQuestionsAnswered(id: $id) {
      id
      JobPostQuestionsId
      JobPostId
      preferencesID
      answeredbyUserId
      Question
      Answer
      TypeOfQuesiton
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listJobPostQuestionsAnswereds = /* GraphQL */ `
  query ListJobPostQuestionsAnswereds(
    $filter: ModelJobPostQuestionsAnsweredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobPostQuestionsAnswereds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostQuestionsId
        JobPostId
        preferencesID
        answeredbyUserId
        Question
        Answer
        TypeOfQuesiton
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobPostAnswerbyJobPostIdandUserId = /* GraphQL */ `
  query GetJobPostAnswerbyJobPostIdandUserId(
    $JobPostQuestionsId: ID!
    $answeredbyUserId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobPostQuestionsAnsweredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getJobPostAnswerbyJobPostIdandUserId(
      JobPostQuestionsId: $JobPostQuestionsId
      answeredbyUserId: $answeredbyUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostQuestionsId
        JobPostId
        preferencesID
        answeredbyUserId
        Question
        Answer
        TypeOfQuesiton
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobTraits = /* GraphQL */ `
  query GetJobTraits($id: ID!) {
    getJobTraits(id: $id) {
      id
      TraitName
      createdAt
      updatedAt
      preferencesID
      owner
      __typename
    }
  }
`;
export const listJobTraits = /* GraphQL */ `
  query ListJobTraits(
    $filter: ModelJobTraitsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobTraits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        TraitName
        createdAt
        updatedAt
        preferencesID
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listsTraitsByPreferencesId = /* GraphQL */ `
  query ListsTraitsByPreferencesId(
    $preferencesID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobTraitsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listsTraitsByPreferencesId(
      preferencesID: $preferencesID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        TraitName
        createdAt
        updatedAt
        preferencesID
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShortlist = /* GraphQL */ `
  query GetShortlist($id: ID!) {
    getShortlist(id: $id) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      about
      candidateprofileId
      shortlistedbyId
      JobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      shortlistedByUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        UserRole
        updatedAt
        owner
        __typename
      }
      recommededId
      preferencesId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        CandidateEngagementType
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      id
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const listShortlists = /* GraphQL */ `
  query ListShortlists(
    $filter: ModelShortlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShortlists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        businessProfileId
        businessUserId
        UserId
        about
        candidateprofileId
        shortlistedbyId
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        shortlistedByUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        recommededId
        preferencesId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        id
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shortlistsByBusinessProfileId = /* GraphQL */ `
  query ShortlistsByBusinessProfileId(
    $businessProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelshortlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shortlistsByBusinessProfileId(
      businessProfileId: $businessProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        UserId
        about
        candidateprofileId
        shortlistedbyId
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        shortlistedByUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        recommededId
        preferencesId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        id
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listbusinessprofileByUserId = /* GraphQL */ `
  query ListbusinessprofileByUserId(
    $businessUserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelshortlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listbusinessprofileByUserId(
      businessUserId: $businessUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        UserId
        about
        candidateprofileId
        shortlistedbyId
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        shortlistedByUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        recommededId
        preferencesId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        id
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listshortlistByUserIdByDate = /* GraphQL */ `
  query ListshortlistByUserIdByDate(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelshortlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listshortlistByUserIdByDate(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        UserId
        about
        candidateprofileId
        shortlistedbyId
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        shortlistedByUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        recommededId
        preferencesId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        id
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const checkShortlisteduser = /* GraphQL */ `
  query CheckShortlisteduser(
    $candidateprofileId: ID!
    $preferencesId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelshortlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkShortlisteduser(
      candidateprofileId: $candidateprofileId
      preferencesId: $preferencesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        UserId
        about
        candidateprofileId
        shortlistedbyId
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        shortlistedByUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        recommededId
        preferencesId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        id
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const GetshortlistByjobPostIdfilterByUserId = /* GraphQL */ `
  query GetshortlistByjobPostIdfilterByUserId(
    $JobPostId: ID!
    $UserId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelshortlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GetshortlistByjobPostIdfilterByUserId(
      JobPostId: $JobPostId
      UserId: $UserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        UserId
        about
        candidateprofileId
        shortlistedbyId
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        shortlistedByUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        recommededId
        preferencesId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        id
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listshortlistsByPreferencesByDate = /* GraphQL */ `
  query ListshortlistsByPreferencesByDate(
    $preferencesId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelshortlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listshortlistsByPreferencesByDate(
      preferencesId: $preferencesId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        UserId
        about
        candidateprofileId
        shortlistedbyId
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        shortlistedByUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        recommededId
        preferencesId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        id
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRejected = /* GraphQL */ `
  query GetRejected($id: ID!) {
    getRejected(id: $id) {
      createdAt
      businessProfileId
      businessUserId
      shortlistedbyId
      shortlistedByUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        UserRole
        updatedAt
        owner
        __typename
      }
      UserId
      about
      candidateprofileId
      Reasonofrejection
      recommededId
      preferencesId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        CandidateEngagementType
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      id
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRejecteds = /* GraphQL */ `
  query ListRejecteds(
    $filter: ModelRejectedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRejecteds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        businessProfileId
        businessUserId
        shortlistedbyId
        shortlistedByUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        UserId
        about
        candidateprofileId
        Reasonofrejection
        recommededId
        preferencesId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        id
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const rejectedsByBusinessProfileId = /* GraphQL */ `
  query RejectedsByBusinessProfileId(
    $businessProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRejectedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rejectedsByBusinessProfileId(
      businessProfileId: $businessProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        shortlistedbyId
        shortlistedByUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        UserId
        about
        candidateprofileId
        Reasonofrejection
        recommededId
        preferencesId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        id
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listRejectedByUserId = /* GraphQL */ `
  query ListRejectedByUserId(
    $businessUserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRejectedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRejectedByUserId(
      businessUserId: $businessUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        shortlistedbyId
        shortlistedByUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        UserId
        about
        candidateprofileId
        Reasonofrejection
        recommededId
        preferencesId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        id
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listRejectedByUserIdByDate = /* GraphQL */ `
  query ListRejectedByUserIdByDate(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRejectedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRejectedByUserIdByDate(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        shortlistedbyId
        shortlistedByUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        UserId
        about
        candidateprofileId
        Reasonofrejection
        recommededId
        preferencesId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        id
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const checkRejecteduser = /* GraphQL */ `
  query CheckRejecteduser(
    $candidateprofileId: ID!
    $preferencesId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRejectedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkRejecteduser(
      candidateprofileId: $candidateprofileId
      preferencesId: $preferencesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        shortlistedbyId
        shortlistedByUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        UserId
        about
        candidateprofileId
        Reasonofrejection
        recommededId
        preferencesId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        id
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listRejectedByPreferencesByDate = /* GraphQL */ `
  query ListRejectedByPreferencesByDate(
    $preferencesId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRejectedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRejectedByPreferencesByDate(
      preferencesId: $preferencesId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        shortlistedbyId
        shortlistedByUser {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        UserId
        about
        candidateprofileId
        Reasonofrejection
        recommededId
        preferencesId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        id
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecommendedprofile = /* GraphQL */ `
  query GetRecommendedprofile($id: ID!) {
    getRecommendedprofile(id: $id) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      candidateprofileId
      acceptanceStatus
      ReasonofRecommendation
      ReasonofShortlist
      Reasonofrejection
      acceptanceStatus_by_CompanyUser_ID
      acceptanceStatus_by_CompanyUser_name
      recommendedbyId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        CandidateEngagementType
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      preferencesId
      companyQrewId
      JobApplicationsId
      JobApplication {
        id
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        CompanyProfileId
        ProfileId
        CompanyPreferenceId
        companyQrewId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ApplyingStatus
        Level
        CandidateProfileAnalysis
        createdAt
        updatedAt
        owner
        __typename
      }
      JobPostId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      new
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRecommendedprofiles = /* GraphQL */ `
  query ListRecommendedprofiles(
    $filter: ModelRecommendedprofileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecommendedprofiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        UserId
        candidateprofileId
        acceptanceStatus
        ReasonofRecommendation
        ReasonofShortlist
        Reasonofrejection
        acceptanceStatus_by_CompanyUser_ID
        acceptanceStatus_by_CompanyUser_name
        recommendedbyId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        preferencesId
        companyQrewId
        JobApplicationsId
        JobApplication {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        JobPostId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        new
        id
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listrecommendedprofileBybusinessProfileIdByDate = /* GraphQL */ `
  query ListrecommendedprofileBybusinessProfileIdByDate(
    $businessProfileId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelrecommendedprofileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listrecommendedprofileBybusinessProfileIdByDate(
      businessProfileId: $businessProfileId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        UserId
        candidateprofileId
        acceptanceStatus
        ReasonofRecommendation
        ReasonofShortlist
        Reasonofrejection
        acceptanceStatus_by_CompanyUser_ID
        acceptanceStatus_by_CompanyUser_name
        recommendedbyId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        preferencesId
        companyQrewId
        JobApplicationsId
        JobApplication {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        JobPostId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        new
        id
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listrecommendedprofileBybusinessUserIdByDate = /* GraphQL */ `
  query ListrecommendedprofileBybusinessUserIdByDate(
    $businessUserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelrecommendedprofileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listrecommendedprofileBybusinessUserIdByDate(
      businessUserId: $businessUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        UserId
        candidateprofileId
        acceptanceStatus
        ReasonofRecommendation
        ReasonofShortlist
        Reasonofrejection
        acceptanceStatus_by_CompanyUser_ID
        acceptanceStatus_by_CompanyUser_name
        recommendedbyId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        preferencesId
        companyQrewId
        JobApplicationsId
        JobApplication {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        JobPostId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        new
        id
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listrecommednedprofileByUserIdByDate = /* GraphQL */ `
  query ListrecommednedprofileByUserIdByDate(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelrecommendedprofileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listrecommednedprofileByUserIdByDate(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        UserId
        candidateprofileId
        acceptanceStatus
        ReasonofRecommendation
        ReasonofShortlist
        Reasonofrejection
        acceptanceStatus_by_CompanyUser_ID
        acceptanceStatus_by_CompanyUser_name
        recommendedbyId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        preferencesId
        companyQrewId
        JobApplicationsId
        JobApplication {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        JobPostId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        new
        id
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const checkRecommendeduser = /* GraphQL */ `
  query CheckRecommendeduser(
    $candidateprofileId: ID!
    $preferencesId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelrecommendedprofileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkRecommendeduser(
      candidateprofileId: $candidateprofileId
      preferencesId: $preferencesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        UserId
        candidateprofileId
        acceptanceStatus
        ReasonofRecommendation
        ReasonofShortlist
        Reasonofrejection
        acceptanceStatus_by_CompanyUser_ID
        acceptanceStatus_by_CompanyUser_name
        recommendedbyId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        preferencesId
        companyQrewId
        JobApplicationsId
        JobApplication {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        JobPostId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        new
        id
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listrecommendedprofieByPreferencesByAcceptance = /* GraphQL */ `
  query ListrecommendedprofieByPreferencesByAcceptance(
    $preferencesId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelrecommendedprofileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listrecommendedprofieByPreferencesByAcceptance(
      preferencesId: $preferencesId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        UserId
        candidateprofileId
        acceptanceStatus
        ReasonofRecommendation
        ReasonofShortlist
        Reasonofrejection
        acceptanceStatus_by_CompanyUser_ID
        acceptanceStatus_by_CompanyUser_name
        recommendedbyId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        preferencesId
        companyQrewId
        JobApplicationsId
        JobApplication {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        JobPostId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        new
        id
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listrecommendedprofiesBycompanyQrewId = /* GraphQL */ `
  query ListrecommendedprofiesBycompanyQrewId(
    $companyQrewId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelrecommendedprofileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listrecommendedprofiesBycompanyQrewId(
      companyQrewId: $companyQrewId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        businessProfileId
        businessUserId
        UserId
        candidateprofileId
        acceptanceStatus
        ReasonofRecommendation
        ReasonofShortlist
        Reasonofrejection
        acceptanceStatus_by_CompanyUser_ID
        acceptanceStatus_by_CompanyUser_name
        recommendedbyId
        prferenceType {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        preferencesId
        companyQrewId
        JobApplicationsId
        JobApplication {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        JobPostId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        businessProfile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        new
        id
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDeployedQrewHistory = /* GraphQL */ `
  query GetDeployedQrewHistory($id: ID!) {
    getDeployedQrewHistory(id: $id) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      finalCTCQrew
      candidateprofileId
      qrewPosition
      preferencesId
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      CandidateAcceptanceStatus
      CandidateContractRejectionReason
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      NewContractCTC
      PaymentCurrency
      HourlyRate
      ContractEndedBy
      ContractPausedBy
      JobApplicationsId
      deployedQrewId
      id
      owner
      __typename
    }
  }
`;
export const listDeployedQrewHistories = /* GraphQL */ `
  query ListDeployedQrewHistories(
    $filter: ModelDeployedQrewHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeployedQrewHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        finalCTCQrew
        candidateprofileId
        qrewPosition
        preferencesId
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        NewContractCTC
        PaymentCurrency
        HourlyRate
        ContractEndedBy
        ContractPausedBy
        JobApplicationsId
        deployedQrewId
        id
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deployedQrewHistoriesByDeployedQrewId = /* GraphQL */ `
  query DeployedQrewHistoriesByDeployedQrewId(
    $deployedQrewId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModeldeployedQrewHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deployedQrewHistoriesByDeployedQrewId(
      deployedQrewId: $deployedQrewId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        finalCTCQrew
        candidateprofileId
        qrewPosition
        preferencesId
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        NewContractCTC
        PaymentCurrency
        HourlyRate
        ContractEndedBy
        ContractPausedBy
        JobApplicationsId
        deployedQrewId
        id
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDeployedQrew = /* GraphQL */ `
  query GetDeployedQrew($id: ID!) {
    getDeployedQrew(id: $id) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      finalCTCQrew
      candidateprofileId
      qrewPosition
      deployedUserProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      preferencesId
      JobPostPreferences {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        CandidateEngagementType
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      deployedQrewHistory {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          finalCTCQrew
          candidateprofileId
          qrewPosition
          preferencesId
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          NewContractCTC
          PaymentCurrency
          HourlyRate
          ContractEndedBy
          ContractPausedBy
          JobApplicationsId
          deployedQrewId
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      CandidateAcceptanceStatus
      CandidateContractRejectionReason
      CandidateContractNegotiatingReason
      PaymentCurrency
      HourlyRate
      NewContractCTC
      ContractEndedBy
      ContractExtendedBy
      ContractPausedBy
      ContractPausedDate
      JobApplicationsId
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            targetPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      editObjectiveHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      id
      owner
      __typename
    }
  }
`;
export const listDeployedQrews = /* GraphQL */ `
  query ListDeployedQrews(
    $filter: ModelDeployedQrewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeployedQrews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            PaymentCurrency
            HourlyRate
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        PaymentCurrency
        HourlyRate
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        ContractPausedDate
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listdeployedQrewbyQrewId = /* GraphQL */ `
  query ListdeployedQrewbyQrewId(
    $companyQrewId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeldeployedQrewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listdeployedQrewbyQrewId(
      companyQrewId: $companyQrewId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            PaymentCurrency
            HourlyRate
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        PaymentCurrency
        HourlyRate
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        ContractPausedDate
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listdeployedQrewbybusinessProfileId = /* GraphQL */ `
  query ListdeployedQrewbybusinessProfileId(
    $businessProfileId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeldeployedQrewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listdeployedQrewbybusinessProfileId(
      businessProfileId: $businessProfileId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            PaymentCurrency
            HourlyRate
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        PaymentCurrency
        HourlyRate
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        ContractPausedDate
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listdeployedQrewbycandidateprofileId = /* GraphQL */ `
  query ListdeployedQrewbycandidateprofileId(
    $candidateprofileId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeldeployedQrewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listdeployedQrewbycandidateprofileId(
      candidateprofileId: $candidateprofileId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            PaymentCurrency
            HourlyRate
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        PaymentCurrency
        HourlyRate
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        ContractPausedDate
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deployedQrewsByPreferencesId = /* GraphQL */ `
  query DeployedQrewsByPreferencesId(
    $preferencesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModeldeployedQrewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deployedQrewsByPreferencesId(
      preferencesId: $preferencesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            PaymentCurrency
            HourlyRate
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        PaymentCurrency
        HourlyRate
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        ContractPausedDate
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getObjective = /* GraphQL */ `
  query GetObjective($id: ID!) {
    getObjective(id: $id) {
      id
      createdAt
      updatedAt
      title
      creator
      companyProfileId
      companyUserId
      ObjectivekeyResults {
        items {
          id
          createdAt
          updatedAt
          keyResult
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          progressStatus
          progresssPercentage
          targetPercentage
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrewId
      companyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            targetPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      editHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listObjectives = /* GraphQL */ `
  query ListObjectives(
    $filter: ModelObjectiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjectives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            targetPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listObjectivesByCompanyProfileId = /* GraphQL */ `
  query ListObjectivesByCompanyProfileId(
    $companyProfileId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelObjectiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjectivesByCompanyProfileId(
      companyProfileId: $companyProfileId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            targetPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const objectivesByCompanyUserId = /* GraphQL */ `
  query ObjectivesByCompanyUserId(
    $companyUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObjectiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectivesByCompanyUserId(
      companyUserId: $companyUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            targetPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listObjectivesByCompanyQrewId = /* GraphQL */ `
  query ListObjectivesByCompanyQrewId(
    $companyQrewId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelObjectiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjectivesByCompanyQrewId(
      companyQrewId: $companyQrewId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            targetPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getObjectiveKeyResult = /* GraphQL */ `
  query GetObjectiveKeyResult($id: ID!) {
    getObjectiveKeyResult(id: $id) {
      id
      createdAt
      updatedAt
      keyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveId
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            targetPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      progressStatus
      progresssPercentage
      targetPercentage
      owner
      __typename
    }
  }
`;
export const listObjectiveKeyResults = /* GraphQL */ `
  query ListObjectiveKeyResults(
    $filter: ModelObjectiveKeyResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjectiveKeyResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        keyResult
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        progressStatus
        progresssPercentage
        targetPercentage
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listObjectiveKeyResultsbyObjectiveId = /* GraphQL */ `
  query ListObjectiveKeyResultsbyObjectiveId(
    $objectiveId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelObjectiveKeyResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjectiveKeyResultsbyObjectiveId(
      objectiveId: $objectiveId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        keyResult
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        progressStatus
        progresssPercentage
        targetPercentage
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getObjectiveKeyResultProgressHistory = /* GraphQL */ `
  query GetObjectiveKeyResultProgressHistory($id: ID!) {
    getObjectiveKeyResultProgressHistory(id: $id) {
      id
      createdAt
      updatedAt
      ObjectiveKeyResultId
      previousPercentageProgress
      ResponsibleInitiativeId
      prevkeyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveId
      owner
      __typename
    }
  }
`;
export const listObjectiveKeyResultProgressHistories = /* GraphQL */ `
  query ListObjectiveKeyResultProgressHistories(
    $filter: ModelObjectiveKeyResultProgressHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjectiveKeyResultProgressHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        ObjectiveKeyResultId
        previousPercentageProgress
        ResponsibleInitiativeId
        prevkeyResult
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const objectiveKeyResultProgressHistoriesByObjectiveKeyResultId = /* GraphQL */ `
  query ObjectiveKeyResultProgressHistoriesByObjectiveKeyResultId(
    $ObjectiveKeyResultId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObjectiveKeyResultProgressHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectiveKeyResultProgressHistoriesByObjectiveKeyResultId(
      ObjectiveKeyResultId: $ObjectiveKeyResultId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        ObjectiveKeyResultId
        previousPercentageProgress
        ResponsibleInitiativeId
        prevkeyResult
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getObjectiveEditHistory = /* GraphQL */ `
  query GetObjectiveEditHistory($id: ID!) {
    getObjectiveEditHistory(id: $id) {
      id
      createdAt
      updatedAt
      objectiveId
      Objective {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            targetPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      prevTitle
      editedbyCompanyUserProfileId
      editbyCompanyUserProfile {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        UserRole
        updatedAt
        owner
        __typename
      }
      editedbyDeployedQrewId
      editedDeployedQrewProfile {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            PaymentCurrency
            HourlyRate
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        PaymentCurrency
        HourlyRate
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        ContractPausedDate
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listObjectiveEditHistories = /* GraphQL */ `
  query ListObjectiveEditHistories(
    $filter: ModelObjectiveEditHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjectiveEditHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        objectiveId
        Objective {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        prevTitle
        editedbyCompanyUserProfileId
        editbyCompanyUserProfile {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        editedbyDeployedQrewId
        editedDeployedQrewProfile {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              PaymentCurrency
              HourlyRate
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          PaymentCurrency
          HourlyRate
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          ContractPausedDate
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const objectiveEditHistoriesByObjectiveId = /* GraphQL */ `
  query ObjectiveEditHistoriesByObjectiveId(
    $objectiveId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObjectiveEditHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectiveEditHistoriesByObjectiveId(
      objectiveId: $objectiveId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        objectiveId
        Objective {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        prevTitle
        editedbyCompanyUserProfileId
        editbyCompanyUserProfile {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        editedbyDeployedQrewId
        editedDeployedQrewProfile {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              PaymentCurrency
              HourlyRate
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          PaymentCurrency
          HourlyRate
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          ContractPausedDate
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const objectiveEditHistoriesByEditedbyCompanyUserProfileId = /* GraphQL */ `
  query ObjectiveEditHistoriesByEditedbyCompanyUserProfileId(
    $editedbyCompanyUserProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObjectiveEditHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectiveEditHistoriesByEditedbyCompanyUserProfileId(
      editedbyCompanyUserProfileId: $editedbyCompanyUserProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        objectiveId
        Objective {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        prevTitle
        editedbyCompanyUserProfileId
        editbyCompanyUserProfile {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        editedbyDeployedQrewId
        editedDeployedQrewProfile {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              PaymentCurrency
              HourlyRate
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          PaymentCurrency
          HourlyRate
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          ContractPausedDate
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const objectiveEditHistoriesByEditedbyDeployedQrewId = /* GraphQL */ `
  query ObjectiveEditHistoriesByEditedbyDeployedQrewId(
    $editedbyDeployedQrewId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObjectiveEditHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectiveEditHistoriesByEditedbyDeployedQrewId(
      editedbyDeployedQrewId: $editedbyDeployedQrewId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        objectiveId
        Objective {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        prevTitle
        editedbyCompanyUserProfileId
        editbyCompanyUserProfile {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          UserRole
          updatedAt
          owner
          __typename
        }
        editedbyDeployedQrewId
        editedDeployedQrewProfile {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              PaymentCurrency
              HourlyRate
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          PaymentCurrency
          HourlyRate
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          ContractPausedDate
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInitiative = /* GraphQL */ `
  query GetInitiative($id: ID!) {
    getInitiative(id: $id) {
      id
      createdAt
      updatedAt
      initiative
      initiativeDocumentation
      keyResultsAcheived
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveKeyResultId
      objectiveId
      approvalStatus
      objective {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            targetPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      ObjectiveKeyResult {
        id
        createdAt
        updatedAt
        keyResult
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        progressStatus
        progresssPercentage
        targetPercentage
        owner
        __typename
      }
      InitiativeCreatedBydeployedQrew {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            PaymentCurrency
            HourlyRate
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        PaymentCurrency
        HourlyRate
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        ContractPausedDate
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      PercentageContributionOnkeyResult
      TimeRequiredToComplete
      owner
      __typename
    }
  }
`;
export const listInitiatives = /* GraphQL */ `
  query ListInitiatives(
    $filter: ModelInitiativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInitiatives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        initiative
        initiativeDocumentation
        keyResultsAcheived
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveKeyResultId
        objectiveId
        approvalStatus
        objective {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        ObjectiveKeyResult {
          id
          createdAt
          updatedAt
          keyResult
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          progressStatus
          progresssPercentage
          targetPercentage
          owner
          __typename
        }
        InitiativeCreatedBydeployedQrew {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              PaymentCurrency
              HourlyRate
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          PaymentCurrency
          HourlyRate
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          ContractPausedDate
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        PercentageContributionOnkeyResult
        TimeRequiredToComplete
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listInitiativesBydeployedQrewId = /* GraphQL */ `
  query ListInitiativesBydeployedQrewId(
    $deployedQrewId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInitiativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInitiativesBydeployedQrewId(
      deployedQrewId: $deployedQrewId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        initiative
        initiativeDocumentation
        keyResultsAcheived
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveKeyResultId
        objectiveId
        approvalStatus
        objective {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        ObjectiveKeyResult {
          id
          createdAt
          updatedAt
          keyResult
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          progressStatus
          progresssPercentage
          targetPercentage
          owner
          __typename
        }
        InitiativeCreatedBydeployedQrew {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              PaymentCurrency
              HourlyRate
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          PaymentCurrency
          HourlyRate
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          ContractPausedDate
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        PercentageContributionOnkeyResult
        TimeRequiredToComplete
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listInitiativesByCompanyProfileId = /* GraphQL */ `
  query ListInitiativesByCompanyProfileId(
    $companyProfileId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInitiativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInitiativesByCompanyProfileId(
      companyProfileId: $companyProfileId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        initiative
        initiativeDocumentation
        keyResultsAcheived
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveKeyResultId
        objectiveId
        approvalStatus
        objective {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        ObjectiveKeyResult {
          id
          createdAt
          updatedAt
          keyResult
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          progressStatus
          progresssPercentage
          targetPercentage
          owner
          __typename
        }
        InitiativeCreatedBydeployedQrew {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              PaymentCurrency
              HourlyRate
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          PaymentCurrency
          HourlyRate
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          ContractPausedDate
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        PercentageContributionOnkeyResult
        TimeRequiredToComplete
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listInitiativesByCompanyQrewId = /* GraphQL */ `
  query ListInitiativesByCompanyQrewId(
    $companyQrewId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInitiativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInitiativesByCompanyQrewId(
      companyQrewId: $companyQrewId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        initiative
        initiativeDocumentation
        keyResultsAcheived
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveKeyResultId
        objectiveId
        approvalStatus
        objective {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        ObjectiveKeyResult {
          id
          createdAt
          updatedAt
          keyResult
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          progressStatus
          progresssPercentage
          targetPercentage
          owner
          __typename
        }
        InitiativeCreatedBydeployedQrew {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              PaymentCurrency
              HourlyRate
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          PaymentCurrency
          HourlyRate
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          ContractPausedDate
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        PercentageContributionOnkeyResult
        TimeRequiredToComplete
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listInistativeByObjectiveKeyResultId = /* GraphQL */ `
  query ListInistativeByObjectiveKeyResultId(
    $objectiveKeyResultId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInitiativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInistativeByObjectiveKeyResultId(
      objectiveKeyResultId: $objectiveKeyResultId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        initiative
        initiativeDocumentation
        keyResultsAcheived
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveKeyResultId
        objectiveId
        approvalStatus
        objective {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        ObjectiveKeyResult {
          id
          createdAt
          updatedAt
          keyResult
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          progressStatus
          progresssPercentage
          targetPercentage
          owner
          __typename
        }
        InitiativeCreatedBydeployedQrew {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              PaymentCurrency
              HourlyRate
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          PaymentCurrency
          HourlyRate
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          ContractPausedDate
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        PercentageContributionOnkeyResult
        TimeRequiredToComplete
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listInitiativesByObjectiveId = /* GraphQL */ `
  query ListInitiativesByObjectiveId(
    $objectiveId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInitiativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInitiativesByObjectiveId(
      objectiveId: $objectiveId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        initiative
        initiativeDocumentation
        keyResultsAcheived
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveKeyResultId
        objectiveId
        approvalStatus
        objective {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              targetPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        ObjectiveKeyResult {
          id
          createdAt
          updatedAt
          keyResult
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          progressStatus
          progresssPercentage
          targetPercentage
          owner
          __typename
        }
        InitiativeCreatedBydeployedQrew {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              PaymentCurrency
              HourlyRate
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          PaymentCurrency
          HourlyRate
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          ContractPausedDate
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        PercentageContributionOnkeyResult
        TimeRequiredToComplete
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTalent = /* GraphQL */ `
  query GetTalent($id: ID!) {
    getTalent(id: $id) {
      createdAt
      hidiId
      UserId
      Spotter
      description
      id
      Talent
      Level
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      hidi {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTalents = /* GraphQL */ `
  query ListTalents(
    $filter: ModelTalentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTalents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        hidiId
        UserId
        Spotter
        description
        id
        Talent
        Level
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        hidi {
          createdAt
          Email
          id
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          MobileNumber
          name
          UserId
          PSStitle
          PSSdescription
          DMStitle
          DMSdescription
          CTtitle
          CTdescription
          Atitle
          Adescription
          Verified
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTalentssByhidiIdByDate = /* GraphQL */ `
  query ListTalentssByhidiIdByDate(
    $hidiId: ID!
    $Talent: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTalentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTalentssByhidiIdByDate(
      hidiId: $hidiId
      Talent: $Talent
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        hidiId
        UserId
        Spotter
        description
        id
        Talent
        Level
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        hidi {
          createdAt
          Email
          id
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          MobileNumber
          name
          UserId
          PSStitle
          PSSdescription
          DMStitle
          DMSdescription
          CTtitle
          CTdescription
          Atitle
          Adescription
          Verified
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTalentsByUserIdByDate = /* GraphQL */ `
  query ListTalentsByUserIdByDate(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTalentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTalentsByUserIdByDate(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        hidiId
        UserId
        Spotter
        description
        id
        Talent
        Level
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        hidi {
          createdAt
          Email
          id
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          MobileNumber
          name
          UserId
          PSStitle
          PSSdescription
          DMStitle
          DMSdescription
          CTtitle
          CTdescription
          Atitle
          Adescription
          Verified
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSkill = /* GraphQL */ `
  query GetSkill($id: ID!) {
    getSkill(id: $id) {
      createdAt
      profileId
      UserId
      description
      YearsOfExperience
      id
      Skill
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      JoinedCapabilities {
        items {
          id
          UserId
          SkillId
          CapabilityId
          Skill {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Capabilities {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          GlobalCapabilityConfigId
          GlobalCapabilityConfigName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        profileId
        UserId
        description
        YearsOfExperience
        id
        Skill
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        JoinedCapabilities {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSkillsByUserIdByDate = /* GraphQL */ `
  query ListSkillsByUserIdByDate(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkillsByUserIdByDate(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        profileId
        UserId
        description
        YearsOfExperience
        id
        Skill
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        JoinedCapabilities {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJoinSkillandCapability = /* GraphQL */ `
  query GetJoinSkillandCapability($id: ID!) {
    getJoinSkillandCapability(id: $id) {
      id
      UserId
      SkillId
      CapabilityId
      Skill {
        createdAt
        profileId
        UserId
        description
        YearsOfExperience
        id
        Skill
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        JoinedCapabilities {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Capabilities {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      GlobalCapabilityConfigId
      GlobalCapabilityConfigName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listJoinSkillandCapabilities = /* GraphQL */ `
  query ListJoinSkillandCapabilities(
    $filter: ModelJoinSkillandCapabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJoinSkillandCapabilities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        UserId
        SkillId
        CapabilityId
        Skill {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Capabilities {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        GlobalCapabilityConfigId
        GlobalCapabilityConfigName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listJoinSkillAndCapabilitiesByUserId = /* GraphQL */ `
  query ListJoinSkillAndCapabilitiesByUserId(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJoinSkillandCapabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJoinSkillAndCapabilitiesByUserId(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        UserId
        SkillId
        CapabilityId
        Skill {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Capabilities {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        GlobalCapabilityConfigId
        GlobalCapabilityConfigName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const joinSkillandCapabilitiesBySkillId = /* GraphQL */ `
  query JoinSkillandCapabilitiesBySkillId(
    $SkillId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJoinSkillandCapabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    joinSkillandCapabilitiesBySkillId(
      SkillId: $SkillId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        UserId
        SkillId
        CapabilityId
        Skill {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Capabilities {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        GlobalCapabilityConfigId
        GlobalCapabilityConfigName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const joinSkillandCapabilitiesByCapabilityId = /* GraphQL */ `
  query JoinSkillandCapabilitiesByCapabilityId(
    $CapabilityId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJoinSkillandCapabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    joinSkillandCapabilitiesByCapabilityId(
      CapabilityId: $CapabilityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        UserId
        SkillId
        CapabilityId
        Skill {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Capabilities {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        GlobalCapabilityConfigId
        GlobalCapabilityConfigName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCapabilities = /* GraphQL */ `
  query GetCapabilities($id: ID!) {
    getCapabilities(id: $id) {
      createdAt
      ProfileId
      id
      PointsEarned {
        items {
          id
          Project
          Hack
          ProjectLink
          Video
          Photo
          Presentation
          experience
          Claim
          Outcomes
          PeersVouch
          GlobalCapabilityConfigId
          Helped
          Problem
          Challenges
          CapabilityPoints
          UserId
          capabilityPointsCapabilityClaimedId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      userProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ConnectedHidis {
        items {
          CapabilityId
          HidiId
          Capability {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          createdAt
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      capabilitiesCapabilityConfigId
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      JoinedSkills {
        items {
          id
          UserId
          SkillId
          CapabilityId
          Skill {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Capabilities {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          GlobalCapabilityConfigId
          GlobalCapabilityConfigName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCapabilities = /* GraphQL */ `
  query ListCapabilities(
    $filter: ModelCapabilitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCapabilities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCapabilitiesByProfileIdByDate = /* GraphQL */ `
  query ListCapabilitiesByProfileIdByDate(
    $ProfileId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCapabilitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCapabilitiesByProfileIdByDate(
      ProfileId: $ProfileId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const capabilitiesByCapabilitiesCapabilityConfigId = /* GraphQL */ `
  query CapabilitiesByCapabilitiesCapabilityConfigId(
    $capabilitiesCapabilityConfigId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCapabilitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    capabilitiesByCapabilitiesCapabilityConfigId(
      capabilitiesCapabilityConfigId: $capabilitiesCapabilityConfigId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJoinHidisAndCapabilities = /* GraphQL */ `
  query GetJoinHidisAndCapabilities($id: ID!) {
    getJoinHidisAndCapabilities(id: $id) {
      CapabilityId
      HidiId
      Capability {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        MeatFoundbyAI
        MeatFoundbyHuman
        MeatWeightagebyHuman
        AiAnalysisForMeat
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const listJoinHidisAndCapabilities = /* GraphQL */ `
  query ListJoinHidisAndCapabilities(
    $filter: ModelJoinHidisAndCapabilitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJoinHidisAndCapabilities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        CapabilityId
        HidiId
        Capability {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Hidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        createdAt
        id
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_Hidis_by_Capability_Id = /* GraphQL */ `
  query List_Hidis_by_Capability_Id(
    $CapabilityId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJoinHidisAndCapabilitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_Hidis_by_Capability_Id(
      CapabilityId: $CapabilityId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        CapabilityId
        HidiId
        Capability {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Hidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        createdAt
        id
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_Capabilities_by_HidiId = /* GraphQL */ `
  query List_Capabilities_by_HidiId(
    $HidiId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJoinHidisAndCapabilitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_Capabilities_by_HidiId(
      HidiId: $HidiId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        CapabilityId
        HidiId
        Capability {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Hidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        createdAt
        id
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGlobalCapabilityConfig = /* GraphQL */ `
  query GetGlobalCapabilityConfig($id: ID!) {
    getGlobalCapabilityConfig(id: $id) {
      id
      name
      tag
      domain
      createdAt
      weeklyActiveOpportunity
      capabilitiesClaimedCount
      CapabilityPoints
      capabilitiesClaimed {
        items {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      feeds {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connectedJobPosts {
        items {
          id
          jobPostID
          GlobalCapabilityConfigID
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ActivityStatus
      updatedAt
      owner
      __typename
    }
  }
`;
export const listGlobalCapabilityConfigs = /* GraphQL */ `
  query ListGlobalCapabilityConfigs(
    $filter: ModelGlobalCapabilityConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlobalCapabilityConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_globalcapbilities_by_ActivityStatus_filer_by_domain = /* GraphQL */ `
  query List_globalcapbilities_by_ActivityStatus_filer_by_domain(
    $ActivityStatus: CapabilityActivityStatus!
    $domain: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGlobalCapabilityConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_globalcapbilities_by_ActivityStatus_filer_by_domain(
      ActivityStatus: $ActivityStatus
      domain: $domain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCapabilityPoints = /* GraphQL */ `
  query GetCapabilityPoints($id: ID!) {
    getCapabilityPoints(id: $id) {
      id
      Project
      Hack
      ProjectLink
      Video
      Photo
      Presentation
      experience
      Claim
      Outcomes
      PeersVouch
      GlobalCapabilityConfigId
      Helped
      Problem
      Challenges
      CapabilityPoints
      UserId
      capabilityPointsCapabilityClaimedId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCapabilityPoints = /* GraphQL */ `
  query ListCapabilityPoints(
    $filter: ModelCapabilityPointsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCapabilityPoints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Project
        Hack
        ProjectLink
        Video
        Photo
        Presentation
        experience
        Claim
        Outcomes
        PeersVouch
        GlobalCapabilityConfigId
        Helped
        Problem
        Challenges
        CapabilityPoints
        UserId
        capabilityPointsCapabilityClaimedId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_Capability_Points_by_UserId = /* GraphQL */ `
  query List_Capability_Points_by_UserId(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCapabilityPointsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_Capability_Points_by_UserId(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Project
        Hack
        ProjectLink
        Video
        Photo
        Presentation
        experience
        Claim
        Outcomes
        PeersVouch
        GlobalCapabilityConfigId
        Helped
        Problem
        Challenges
        CapabilityPoints
        UserId
        capabilityPointsCapabilityClaimedId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const capabilityPointsByCapabilityPointsCapabilityClaimedId = /* GraphQL */ `
  query CapabilityPointsByCapabilityPointsCapabilityClaimedId(
    $capabilityPointsCapabilityClaimedId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCapabilityPointsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    capabilityPointsByCapabilityPointsCapabilityClaimedId(
      capabilityPointsCapabilityClaimedId: $capabilityPointsCapabilityClaimedId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Project
        Hack
        ProjectLink
        Video
        Photo
        Presentation
        experience
        Claim
        Outcomes
        PeersVouch
        GlobalCapabilityConfigId
        Helped
        Problem
        Challenges
        CapabilityPoints
        UserId
        capabilityPointsCapabilityClaimedId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      cognitoId
      id
      username
      usertype
      fullname
      registered
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        cognitoId
        id
        username
        usertype
        fullname
        registered
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSignal = /* GraphQL */ `
  query GetSignal($id: ID!) {
    getSignal(id: $id) {
      createdAt
      hidiId
      UserId
      Spotter
      description
      id
      signalname
      score
      signalcategory
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      hidi {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSignals = /* GraphQL */ `
  query ListSignals(
    $filter: ModelSignalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSignals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        hidiId
        UserId
        Spotter
        description
        id
        signalname
        score
        signalcategory
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        hidi {
          createdAt
          Email
          id
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          MobileNumber
          name
          UserId
          PSStitle
          PSSdescription
          DMStitle
          DMSdescription
          CTtitle
          CTdescription
          Atitle
          Adescription
          Verified
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSignalByhidiIdByDate = /* GraphQL */ `
  query ListSignalByhidiIdByDate(
    $hidiId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSignalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSignalByhidiIdByDate(
      hidiId: $hidiId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        hidiId
        UserId
        Spotter
        description
        id
        signalname
        score
        signalcategory
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        hidi {
          createdAt
          Email
          id
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          MobileNumber
          name
          UserId
          PSStitle
          PSSdescription
          DMStitle
          DMSdescription
          CTtitle
          CTdescription
          Atitle
          Adescription
          Verified
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSignalByUserIdByDate = /* GraphQL */ `
  query ListSignalByUserIdByDate(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSignalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSignalByUserIdByDate(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        hidiId
        UserId
        Spotter
        description
        id
        signalname
        score
        signalcategory
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        hidi {
          createdAt
          Email
          id
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          MobileNumber
          name
          UserId
          PSStitle
          PSSdescription
          DMStitle
          DMSdescription
          CTtitle
          CTdescription
          Atitle
          Adescription
          Verified
          updatedAt
          owner
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserParsedResume = /* GraphQL */ `
  query GetUserParsedResume($id: ID!) {
    getUserParsedResume(id: $id) {
      UserId
      ParsedString
      ResumeText
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserParsedResumes = /* GraphQL */ `
  query ListUserParsedResumes(
    $filter: ModelUserParsedResumeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserParsedResumes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        UserId
        ParsedString
        ResumeText
        createdAt
        id
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserParsedResumebyUserId = /* GraphQL */ `
  query ListUserParsedResumebyUserId(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserParsedResumeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserParsedResumebyUserId(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        UserId
        ParsedString
        ResumeText
        createdAt
        id
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserParsedResumeCurrent = /* GraphQL */ `
  query GetUserParsedResumeCurrent($UserCognitoId: ID!) {
    getUserParsedResumeCurrent(UserCognitoId: $UserCognitoId) {
      UserCognitoId
      ParsedString
      ResumeText
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserParsedResumeCurrents = /* GraphQL */ `
  query ListUserParsedResumeCurrents(
    $UserCognitoId: ID
    $filter: ModelUserParsedResumeCurrentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserParsedResumeCurrents(
      UserCognitoId: $UserCognitoId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        UserCognitoId
        ParsedString
        ResumeText
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeed = /* GraphQL */ `
  query GetFeed($id: ID!) {
    getFeed(id: $id) {
      id
      UserSegmentId
      HidiId
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        MeatFoundbyAI
        MeatFoundbyHuman
        MeatWeightagebyHuman
        AiAnalysisForMeat
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      CapabilityConfigId
      jobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      tags
      domain
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      Description
      Payload
      FeedType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listFeeds = /* GraphQL */ `
  query ListFeeds(
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        UserSegmentId
        HidiId
        Hidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        CapabilityConfigId
        jobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        tags
        domain
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        Description
        Payload
        FeedType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_Feed_by_UserSegmentId = /* GraphQL */ `
  query List_Feed_by_UserSegmentId(
    $UserSegmentId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_Feed_by_UserSegmentId(
      UserSegmentId: $UserSegmentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        UserSegmentId
        HidiId
        Hidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        CapabilityConfigId
        jobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        tags
        domain
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        Description
        Payload
        FeedType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const feedsByHidiId = /* GraphQL */ `
  query FeedsByHidiId(
    $HidiId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedsByHidiId(
      HidiId: $HidiId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        UserSegmentId
        HidiId
        Hidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        CapabilityConfigId
        jobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        tags
        domain
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        Description
        Payload
        FeedType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const feedsByCapabilityConfigId = /* GraphQL */ `
  query FeedsByCapabilityConfigId(
    $CapabilityConfigId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedsByCapabilityConfigId(
      CapabilityConfigId: $CapabilityConfigId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        UserSegmentId
        HidiId
        Hidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        CapabilityConfigId
        jobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        tags
        domain
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        Description
        Payload
        FeedType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const feedsByJobPostId = /* GraphQL */ `
  query FeedsByJobPostId(
    $jobPostId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedsByJobPostId(
      jobPostId: $jobPostId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        UserSegmentId
        HidiId
        Hidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        CapabilityConfigId
        jobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        tags
        domain
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        Description
        Payload
        FeedType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_Feed_by_Domain = /* GraphQL */ `
  query List_Feed_by_Domain(
    $domain: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_Feed_by_Domain(
      domain: $domain
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        UserSegmentId
        HidiId
        Hidi {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          MeatFoundbyAI
          MeatFoundbyHuman
          MeatWeightagebyHuman
          AiAnalysisForMeat
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        CapabilityConfigId
        jobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        tags
        domain
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        Description
        Payload
        FeedType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserSegments = /* GraphQL */ `
  query GetUserSegments($id: ID!) {
    getUserSegments(id: $id) {
      id
      SegmentString
      ConnectedUsers {
        items {
          UserSegmentId
          UserId
          createdAt
          updatedAt
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserSegments = /* GraphQL */ `
  query ListUserSegments(
    $filter: ModelUserSegmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSegments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        SegmentString
        ConnectedUsers {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJoinUserSegmentsandNewProfile = /* GraphQL */ `
  query GetJoinUserSegmentsandNewProfile($id: ID!) {
    getJoinUserSegmentsandNewProfile(id: $id) {
      UserSegmentId
      UserId
      createdAt
      updatedAt
      id
      owner
      __typename
    }
  }
`;
export const listJoinUserSegmentsandNewProfiles = /* GraphQL */ `
  query ListJoinUserSegmentsandNewProfiles(
    $filter: ModelJoinUserSegmentsandNewProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJoinUserSegmentsandNewProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        UserSegmentId
        UserId
        createdAt
        updatedAt
        id
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_users_by_Usersegement_Id = /* GraphQL */ `
  query List_users_by_Usersegement_Id(
    $UserSegmentId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJoinUserSegmentsandNewProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_users_by_Usersegement_Id(
      UserSegmentId: $UserSegmentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        UserSegmentId
        UserId
        createdAt
        updatedAt
        id
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_UserSegments_by_UserId = /* GraphQL */ `
  query List_UserSegments_by_UserId(
    $UserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJoinUserSegmentsandNewProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_UserSegments_by_UserId(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        UserSegmentId
        UserId
        createdAt
        updatedAt
        id
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserEvents = /* GraphQL */ `
  query GetUserEvents($id: ID!) {
    getUserEvents(id: $id) {
      id
      Payload
      UserId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserEvents = /* GraphQL */ `
  query ListUserEvents(
    $filter: ModelUserEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Payload
        UserId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobApplications = /* GraphQL */ `
  query GetJobApplications($id: ID!) {
    getJobApplications(id: $id) {
      id
      JobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      CompanyProfileId
      ProfileId
      CompanyPreferenceId
      companyQrewId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          HourlyRateExpectedOnContract
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        InTalentPoolBoolean
        InTalentPool {
          id
          CandidateProfileId
          CandidateUserId
          CandidateProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          Domain
          CandidateEngagementType
          createdAt
          updatedAt
          Availability
          NoticePeriod
          AvailabilityTime
          AvailabilityLocation
          AvailabilityMode
          Skills
          TotalExperience
          Remarks
          owner
          __typename
        }
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ApplyingStatus
      Level
      CandidateProfileAnalysis
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listJobApplications = /* GraphQL */ `
  query ListJobApplications(
    $filter: ModelJobApplicationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        CompanyProfileId
        ProfileId
        CompanyPreferenceId
        companyQrewId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ApplyingStatus
        Level
        CandidateProfileAnalysis
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_JobApplicants_by_jobPostId = /* GraphQL */ `
  query List_JobApplicants_by_jobPostId(
    $JobPostId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_JobApplicants_by_jobPostId(
      JobPostId: $JobPostId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        CompanyProfileId
        ProfileId
        CompanyPreferenceId
        companyQrewId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ApplyingStatus
        Level
        CandidateProfileAnalysis
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_JobApplicants_by_companyProfileId = /* GraphQL */ `
  query List_JobApplicants_by_companyProfileId(
    $CompanyProfileId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_JobApplicants_by_companyProfileId(
      CompanyProfileId: $CompanyProfileId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        CompanyProfileId
        ProfileId
        CompanyPreferenceId
        companyQrewId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ApplyingStatus
        Level
        CandidateProfileAnalysis
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_JobApplications_by_ProfileId_filter_byJobPostId = /* GraphQL */ `
  query List_JobApplications_by_ProfileId_filter_byJobPostId(
    $ProfileId: ID!
    $JobPostId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_JobApplications_by_ProfileId_filter_byJobPostId(
      ProfileId: $ProfileId
      JobPostId: $JobPostId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        CompanyProfileId
        ProfileId
        CompanyPreferenceId
        companyQrewId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ApplyingStatus
        Level
        CandidateProfileAnalysis
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_JobApplicants_by_companyPreferenceId = /* GraphQL */ `
  query List_JobApplicants_by_companyPreferenceId(
    $CompanyPreferenceId: ID!
    $ApplyingStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_JobApplicants_by_companyPreferenceId(
      CompanyPreferenceId: $CompanyPreferenceId
      ApplyingStatus: $ApplyingStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        CompanyProfileId
        ProfileId
        CompanyPreferenceId
        companyQrewId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ApplyingStatus
        Level
        CandidateProfileAnalysis
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_JobApplicants_by_Level_sortbyCompanyprefernceId = /* GraphQL */ `
  query List_JobApplicants_by_Level_sortbyCompanyprefernceId(
    $Level: Int!
    $CompanyPreferenceId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_JobApplicants_by_Level_sortbyCompanyprefernceId(
      Level: $Level
      CompanyPreferenceId: $CompanyPreferenceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        CompanyProfileId
        ProfileId
        CompanyPreferenceId
        companyQrewId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            HourlyRateExpectedOnContract
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          InTalentPoolBoolean
          InTalentPool {
            id
            CandidateProfileId
            CandidateUserId
            CandidateProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            Domain
            CandidateEngagementType
            createdAt
            updatedAt
            Availability
            NoticePeriod
            AvailabilityTime
            AvailabilityLocation
            AvailabilityMode
            Skills
            TotalExperience
            Remarks
            owner
            __typename
          }
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ApplyingStatus
        Level
        CandidateProfileAnalysis
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobApplicantionRecommendationKPIs = /* GraphQL */ `
  query GetJobApplicantionRecommendationKPIs($id: ID!) {
    getJobApplicantionRecommendationKPIs(id: $id) {
      id
      JobPostId
      CompanyProfileId
      CompanyPreferenceId
      NewApplicants
      NewRecommendations
      TotalApplicants
      companyPersonName
      companyMailId
      companyName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listJobApplicantionRecommendationKPIs = /* GraphQL */ `
  query ListJobApplicantionRecommendationKPIs(
    $filter: ModelJobApplicantionRecommendationKPIsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobApplicantionRecommendationKPIs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostId
        CompanyProfileId
        CompanyPreferenceId
        NewApplicants
        NewRecommendations
        TotalApplicants
        companyPersonName
        companyMailId
        companyName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_JobApplicantion_Recommendation_KPIs_by_jobPostId = /* GraphQL */ `
  query List_JobApplicantion_Recommendation_KPIs_by_jobPostId(
    $JobPostId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicantionRecommendationKPIsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_JobApplicantion_Recommendation_KPIs_by_jobPostId(
      JobPostId: $JobPostId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostId
        CompanyProfileId
        CompanyPreferenceId
        NewApplicants
        NewRecommendations
        TotalApplicants
        companyPersonName
        companyMailId
        companyName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobApplicationRecommednationsMailSent = /* GraphQL */ `
  query GetJobApplicationRecommednationsMailSent($id: ID!) {
    getJobApplicationRecommednationsMailSent(id: $id) {
      id
      JobPostIds
      CompanyUser
      companyPersonName
      companyMailId
      companyName
      mailMessageId
      MailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listJobApplicationRecommednationsMailSents = /* GraphQL */ `
  query ListJobApplicationRecommednationsMailSents(
    $filter: ModelJobApplicationRecommednationsMailSentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobApplicationRecommednationsMailSents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostIds
        CompanyUser
        companyPersonName
        companyMailId
        companyName
        mailMessageId
        MailSent
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventTable = /* GraphQL */ `
  query GetEventTable($id: ID!) {
    getEventTable(id: $id) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEventTables = /* GraphQL */ `
  query ListEventTables(
    $filter: ModelEventTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        EventNameTableKeyId
        EventName
        EventAttributes
        EventLogs
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContractEventTable = /* GraphQL */ `
  query GetContractEventTable($id: ID!) {
    getContractEventTable(id: $id) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventCreatedbyUserCognitoId
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listContractEventTables = /* GraphQL */ `
  query ListContractEventTables(
    $filter: ModelContractEventTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractEventTables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        EventNameTableKeyId
        EventName
        EventAttributes
        EventCreatedbyUserCognitoId
        EventLogs
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobPost = /* GraphQL */ `
  query GetJobPost($id: ID!) {
    getJobPost(id: $id) {
      id
      CompanyPreferenceId
      companyQrewId
      CompanyPreference {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            PaymentCurrency
            HourlyRate
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            ContractPausedDate
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        CandidateEngagementType
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      companyName
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shortlistedCandidates {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            UserRole
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              HourlyRateExpectedOnContract
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            InTalentPoolBoolean
            InTalentPool {
              id
              CandidateProfileId
              CandidateUserId
              Domain
              CandidateEngagementType
              createdAt
              updatedAt
              Availability
              NoticePeriod
              AvailabilityTime
              AvailabilityLocation
              AvailabilityMode
              Skills
              TotalExperience
              Remarks
              owner
              __typename
            }
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobPostQuestions {
        items {
          id
          JobPostId
          preferencesID
          Question
          TypeOfQuesiton
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobPostLink
      logoUrl
      htmldescription
      CompanyDescription
      AvailablityStatus
      experienceRequired
      jobType
      Location
      skills
      domain
      description
      ShortDesc
      RolesandResponsibility
      title
      CapabilitiesRequired {
        items {
          id
          jobPostID
          GlobalCapabilityConfigID
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      FeedsConnected {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            MeatFoundbyAI
            MeatFoundbyHuman
            MeatWeightagebyHuman
            AiAnalysisForMeat
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CapabilitiesRequiredJSON
      createdAt
      updatedAt
      SavedJobPosts {
        items {
          id
          JobPostId
          ProfileId
          UserId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listJobPosts = /* GraphQL */ `
  query ListJobPosts(
    $filter: ModelJobPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const Get_JobPost_by_companyPreferenceId = /* GraphQL */ `
  query Get_JobPost_by_companyPreferenceId(
    $CompanyPreferenceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    Get_JobPost_by_companyPreferenceId(
      CompanyPreferenceId: $CompanyPreferenceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_JobPost_by_Domain = /* GraphQL */ `
  query List_JobPost_by_Domain(
    $domain: String!
    $AvailablityStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_JobPost_by_Domain(
      domain: $domain
      AvailablityStatus: $AvailablityStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJoinGlobalCapabilityconfigAndJobPost = /* GraphQL */ `
  query GetJoinGlobalCapabilityconfigAndJobPost($id: ID!) {
    getJoinGlobalCapabilityconfigAndJobPost(id: $id) {
      id
      jobPostID
      GlobalCapabilityConfigID
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              PaymentCurrency
              HourlyRate
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              ContractPausedDate
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          CandidateEngagementType
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              email
              UserRole
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              CandidateEngagementType
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listJoinGlobalCapabilityconfigAndJobPosts = /* GraphQL */ `
  query ListJoinGlobalCapabilityconfigAndJobPosts(
    $filter: ModelJoinGlobalCapabilityconfigAndJobPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJoinGlobalCapabilityconfigAndJobPosts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobPostID
        GlobalCapabilityConfigID
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const joinGlobalCapabilityconfigAndJobPostsByJobPostID = /* GraphQL */ `
  query JoinGlobalCapabilityconfigAndJobPostsByJobPostID(
    $jobPostID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJoinGlobalCapabilityconfigAndJobPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    joinGlobalCapabilityconfigAndJobPostsByJobPostID(
      jobPostID: $jobPostID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobPostID
        GlobalCapabilityConfigID
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const joinGlobalCapabilityconfigAndJobPostsByGlobalCapabilityConfigID = /* GraphQL */ `
  query JoinGlobalCapabilityconfigAndJobPostsByGlobalCapabilityConfigID(
    $GlobalCapabilityConfigID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJoinGlobalCapabilityconfigAndJobPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    joinGlobalCapabilityconfigAndJobPostsByGlobalCapabilityConfigID(
      GlobalCapabilityConfigID: $GlobalCapabilityConfigID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobPostID
        GlobalCapabilityConfigID
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            CandidateEngagementType
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTrending = /* GraphQL */ `
  query GetTrending($id: ID!) {
    getTrending(id: $id) {
      id
      Domain
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              InTalentPoolBoolean
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              MeatFoundbyAI
              MeatFoundbyHuman
              MeatWeightagebyHuman
              AiAnalysisForMeat
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      trendingCapabilityConfigId
      owner
      __typename
    }
  }
`;
export const listTrendings = /* GraphQL */ `
  query ListTrendings(
    $filter: ModelTrendingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrendings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Domain
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        trendingCapabilityConfigId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
