import { Dimensions } from 'react-native'
import {
	Box,
	Divider,
	HStack,
	ScrollView,
	Text,
	Pressable,
	FlatList,
	Menu,
	MenuIcon,
	MenuItem,
	ArrowLeftIcon,
	Icon,
	Button,
	ButtonText,
	ChevronDownIcon,
	Image,
} from '@gluestack-ui/themed'
import React from 'react'

import { useEffect, useState } from 'react'
import { API, Storage } from 'aws-amplify'
import AboutBox from '../../newComponents/aboutBox/AboutBox'
import { getNewprofileByUserIdForDashboardInsights } from '../../graphql/custom-queries'
import { updateRecommendedprofile } from '../../graphql/custom-mutations'
import { SendEmailtoCandidates } from '../../graphql/queries'
import { MobileHeader } from '../../newComponents/header/Header'
import ProfileAnalysisComponent from './components/ProfileAnalysisComponent'
import { createDeployedQrew } from '../../graphql/mutations'

import { ProfileAnalysis } from '../../graphql/queries'
import {
	createRecommendedprofile,
	createShortlist,
	createRejected,
} from '../../graphql/custom-mutations'

import {
	checkRecommendeduser,
	checkRejecteduser,
	checkShortlisteduser,
} from '../../graphql/custom-queries'
import useUserStore from '../../Userstore/UserStore'
import WorkExperience from '../../newComponents/editCareer/workExperience/WorkExperienceComponent'
import EducationDetails from '../../newComponents/editCareer/educationDetails/EducationDetails'
import CertificateComponent from '../../newComponents/editCareer/certifications/CertificateComponent'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import { showToast } from '../../components/ErrorToast'

import CapabilityHidiOnProfile from '../../newComponents/CapabilityHidiOnProfile'
import ResumeView from '../../newComponents/ResumeView'
import MultipleSelect from '../../newComponents/MultipleSelect'
import DropdownComponent from '../../newComponents/DropdownComponent'

import VideoViewerComponent from '../../newComponents/videoComponent/VideoViewerComponent'
import fetchCurrenciesInfo from '../../customhook/GetCurrencies'
import { convertCurrency } from '../../HeplerFunctions/CurrencyConversion'
import SkillsComponent from '../../newScreens/profileScreen/SkillsComponent'
import { parseResume } from './components/HelperFunctions'

import ButtonComponent from '../../newComponents/ButtonComponent'
import ActiveButtonComponent from '../../newComponents/ActiveButtonComponent'
import InactiveButtonComponent from '../../newComponents/InactiveButtonComponent'
import LightButtonComponent from '../../newComponents/LightButtonComponent'
import { getJobApplications } from '../../graphql/custom-queries'
import ModalComponent from '../../newComponents/ModalComponent'
import LabelWithInputComponent from '../../newComponents/LabelWithInputComponent'
import { UpdateToAlplhaLevel } from '../../HeplerFunctions/CandidateQrewHelperQuery'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function NewProfile({
	navigation,
	route,
	RecommendationId,
	PreferenceId,
	JobPostId,
	UserId,
	JobPostData,
	JobApplicantId,
	headingText,
	acceptancestatus,
	ReasonofRecommendation,
	prefferedSalary,
	minExperience,
	maxExpeirence,
	profileAnalysisProp,
	companyQrewId,
	SelectedTab
}) {

	const profileAnalysis = profileAnalysisProp ? profileAnalysisProp :route?.params?.profileAnalysis
	
	const SelectedTabScreen = SelectedTab ? SelectedTab : route?.params?.SelectedTab

	const JobPostDataProps = JobPostData ? JobPostData : route?.params?.JobPostData
	
	let acceptanceStatus = acceptancestatus
		? acceptancestatus
		: route?.params?.acceptancestatus
	const [loading, setloading] = useState(true)
	const [ProfileAnalysisVariable, setProfileAnalysisVariable] = useState('')
	const [profile, setProfile] = useState('')
	const [preferences, setPreferences] = useState([])
	const [preferencesContract, setPreferencesContract] = useState([])
	const existingSkills = useUserStore((state) => state.userSkills)
	const [hidis, setHidis] = useState([])
	const [shortlistloading, setshortlistloading] = useState(false)
	const [selectedLoading, setSelectedLoading] = useState(false)
	const [rejectloading, setrejectloading] = useState(false)
	const [RemoveLoading, setRemoveLoading] = useState(false)
	const [recommendloading, setrecommendloading] = useState(false)
	const [reasonOfRecommendationModal, setreasonOfRecommendationModal] = useState(false)
	const [reasonOfRecommendationInput, setreasonOfRecommendationInput] = useState('')
	const [lambdaLoading, setLambdaLoading] = useState(false)
	const [FlagLoading, setFlagLoading] = useState(false)
	const [checkloading, setcheckloading] = useState(true)
	const [signedDownloadUrl, setsignedDownloadUrl] = useState('')
	const typeofuser = useUserStore((state) => state.group)
	const [shortlisted, setshortlisted] = useState(false)
	const [rejected, setrejected] = useState(false)
	const [reasonofrejection, setreasonofrejection] = useState('')
	const [recommend, setrecommend] = useState(false)

	const pinnedItems = hidis?.filter((item) => item.pinnedFlag)
	const nonPinnedItems = hidis?.filter((item) => !item.pinnedFlag)
	const [numPages, setNumPages] = useState(0)
	const [pageNumber, setPageNumber] = useState(1)
	const allData = [...pinnedItems, ...nonPinnedItems]
	const newData = allData
	const companyuserprofileId = useUserStore(
		(state) => state.companyuserprofileId
	)
	const companyProfileId = useUserStore((state) => state.companyProfileId)
	const companyUsername = useUserStore((state) => state.companyUsername)
	const CapabilityRequiredforJob = JobPostDataProps?.CapabilitiesRequired?.items
		? JobPostDataProps.CapabilitiesRequired?.items
		: route?.params?.JobPostData.CapabilitiesRequired?.items

	const matchingCapabilities = hidis?.filter((data) =>
		CapabilityRequiredforJob?.some(
			(cap) =>
				cap.GlobalCapabilityConfigID ==
				data?.ConnectedCapability?.items[0]?.Capability
					?.CapabilityConfig?.id
		)
	)
	
	const unmatchedCapabilitiesWithEvidence = hidis?.filter((data) =>
		!CapabilityRequiredforJob?.some(
			(cap) =>
				cap.GlobalCapabilityConfigID ==
				data?.ConnectedCapability?.items[0]?.Capability
					?.CapabilityConfig?.id
		)
	)
	
	const remainingCapability = CapabilityRequiredforJob?.filter(
		(cap) =>
			!hidis.some(
				(data) =>
					data?.ConnectedCapability?.items[0]?.Capability
						?.CapabilityConfig?.id === cap?.GlobalCapabilityConfigID
			)
	)

	const CapabilityClaimed = profile.Capabilities?.items

	const matchedClaimedCapabilities = remainingCapability?.filter((data) =>
		CapabilityClaimed?.some(
			(cap) =>
				cap.capabilitiesCapabilityConfigId == data.CapabilityConfig.id
		)
	)

	const unmatchedCapabilityClaimed = CapabilityClaimed?.filter((claimed) =>
		!matchedClaimedCapabilities?.some(
			(matched) => matched.CapabilityConfig.id === claimed.capabilitiesCapabilityConfigId
		)
	)
	

	const [acceptancePreferences, setAcceptancePreferences] = useState({
		acceptanceChoice: '',
	})

	const acceptanceOptions = [
		{
			label: 'Q-Rate',
			value: 'Q-Rate',
		},
		{
			label: 'Shortlist',
			value: 'Shortlist',
		},
		{
			label: 'Reject',
			value: 'Reject',
		},
	]
	const acceptanceOptionsQrusibleAdmin = [
		{
			label: 'Q-Rate',
			value: 'Q-Rate',
		},
		{
			label: 'Shortlist',
			value: 'Shortlist',
		},
		{
			label: 'Reject',
			value: 'Reject',
		},
		{
			label: 'Deployed',
			value: 'Deployed',
		},
	]
	const CallEmailLambda = async (data) => {
	
		try {
			await API.graphql({
				query: SendEmailtoCandidates,
				variables: {
					EmailBody: data.TypeOfMailenum,
					Emails: data.Emails,
					EmailSubject: data.EmailSubject,
					Link: data.Link,
					EmployerName: data.EmployerName,
					CandidateName: data.CandidateName,
					TypeOfMailenum: data.TypeOfMailenum,
					UserId: data.UserId,
					Jobtitle: data.Jobtitle,
				},
			}).then((response) => {
				console.log('succeeded')
			})
		} catch (error) {
			console.log(error)

			return console.log(error)
		}
	}
	const [currencies, setCurrencies] = useState(null)
	const [selectedCountryInfo, setSelectedCountryInfo] = useState(null)
	const apiUrl = 'https://restcountries.com/v3.1/all'

	const [convertedCurrentSalary, setConvertedCurrentSalary] = useState(null)
	const [convertedExpectedSalary, setConvertedExpectedSalary] = useState(null)

	const baseCountry = JobPostDataProps?.CompanyPreference?.currencyCountry || null

	const currentSalary = preferences?.currentSalary
	const expectedSalary = preferences?.Salaryexpectation
	const renderReasonOfRecommendationnModal = () => {
		return (
			<Box p={windowWidth > 480 ? 30 : 15} gap={50}>
				<Text
					fontSize={18}
					fontWeight="600"
					fontFamily="$fontHeading"
				>
					Give Reason Of Recommendation
				</Text>
				<LabelWithInputComponent 
				height={50}
				
				label={
					'Give Reason of Recommendation'
				}
				placeholder={
					'Enter Reason of Recommendation'
				}
				value={
					reasonOfRecommendationInput
				}
				setValueChange={
					setreasonOfRecommendationInput
				}
				important={true}
				/>
				<HStack justifyContent="flex-end" gap={10}>
					<ButtonComponent
						text={'Skip'}
						color={'$secondary500'}
						borderColor={'$secondary500'}
						onPress={() => {
							setreasonOfRecommendationInput('')
							setreasonOfRecommendationModal(false)
							if(headingText == 'Recommended') {
								updateReasonofShortlist()
							}
							else{
								createrecommendedprofile()
							}
							
						}}
						width={120}
					/>
					<ButtonComponent
						text={'Confirm'}
						onPress={() => {
							
							setreasonOfRecommendationModal(false)
							if(headingText == 'Recommended') {
								updateReasonofShortlist()
							}
							else{
								createrecommendedprofile()
							}
						}}
						width={120}
						// loading={loadingConfirmEdit}
					/>
				</HStack>
			</Box>
		)
	}
	async function checkRecommended() {
		if (profile.id) {
			await API.graphql({
				query: checkRecommendeduser,
				variables: {
					candidateprofileId: profile.id,
					preferencesId: {
						eq: route?.params?.PreferenceId
							? route?.params?.PreferenceId
							: PreferenceId,
					},
					// acceptanceStatus: { eq: 'recommend' },
				},
			})
				.then((res) => {
					if (res.data.checkRecommendeduser.items.length > 0) {
						setrecommend(true)
						setcheckloading(false)
					} else {
						setrecommend(false)
						setcheckloading(false)
					}
				})
				.catch((err) => {
					console.log(err)
				})
		} else {
			return null
		}
	}

	const AcceptanceDropdown = () => {
		return (
			<Box width={200}>
				{selectedLoading ? (
					<Box
						marginTop={windowWidth > 480 ? 20 : 10}
						marginBottom={windowWidth > 480 ? 10 : 5}
						borderWidth={1.5}
						h={43}
						borderColor="#f2f2f2"
						bg="$backgroundGray"
						borderRadius={6}
						alignItems="center"
						justifyContent="center"
					>
						<SpinnerComponent button={true} />
					</Box>
				) : (
					<>
						<DropdownComponent
							placeholder={'Candidate Status'}
							userpreferences={acceptancePreferences}
							setUserPreferences={setAcceptancePreferences}
							property={'acceptanceChoice'}
							data={
								typeofuser === 'companys'
									? acceptanceOptions
									: acceptanceOptionsQrusibleAdmin
							}
							newProfile={true}
							colorAccent={
								acceptanceStatus === 'Shortlisted'
									? 'green'
									: acceptanceStatus === 'Rejected'
									? 'red'
									: null
							}
						/>
					</>
				)}
			</Box>
		)
	}

	useEffect(() => {
		if (acceptanceStatus === 'Shortlisted') {
			setAcceptancePreferences({ acceptanceChoice: 'Shortlist' })
		} else if (acceptanceStatus === 'Rejected') {
			setAcceptancePreferences({ acceptanceChoice: 'Reject' })
		} else if (acceptanceStatus === 'Deployed') {
			setAcceptancePreferences({ acceptanceChoice: 'Deployed' })
		} else if (!acceptanceStatus) {
			setAcceptancePreferences({ acceptanceChoice: 'Q-Rate' })
		}
	}, [acceptanceStatus, acceptancePreferences.acceptanceChoice])

	useEffect(() => {
		if (acceptancePreferences.acceptanceChoice === 'Shortlist') {
			if (headingText == 'Recommended') {
				if (acceptanceStatus !== 'Shortlisted') {
					createRecommendedShortlist('Shortlisted')
				}
			} else {
				createshortlist()
			}
		} else if (acceptancePreferences.acceptanceChoice === 'Reject') {
			if (headingText == 'Recommended') {
				if (acceptanceStatus !== 'Rejected') {
					createRecommendedShortlist('Rejected')
				}
			} else {
				createrejected()
			}
		} else if (acceptancePreferences.acceptanceChoice === 'Deployed') {
			if (headingText == 'Recommended') {
				if (acceptanceStatus !== 'Deployed') {
					createRecommendedShortlist('Deployed')
				}
			} else {
				createrejected()
			}
		} else if (acceptancePreferences.acceptanceChoice === 'Q-Rate') {
			if (acceptanceStatus) {
				RemoveAcceptanceStatus()
			}
		}
	}, [acceptancePreferences.acceptanceChoice])

	useEffect(() => {
		setshortlisted(false)
		setrejected(false)
		setcheckloading(true)
		if (acceptanceStatus == 'Shortlisted') {
			setshortlisted(true)
		}
		if (acceptanceStatus == 'Rejected') {
			setrejected(true)
		}
		if (typeofuser == 'QrusibleSuperAdmin') {
			checkRecommended()
		}

		// checkrejected()
	}, [profile])

	useEffect(() => {
		setProfileAnalysisVariable(profileAnalysis)
	}, [profileAnalysis])

	const UpdatetoAplhaOrQfactor = async(item) => {
		
		try {
			setFlagLoading(true)
			
				
			
			
			
			
			const input = {
				
				id: JobApplicantId,
				Level : item
				
			}
			
				const response = await UpdateToAlplhaLevel(input)

				showToast({
				
					message : item == 999 ? 'Profile added to Alpha' :
										 item == 1000 ? 'Profile added to Q-factor' :
										 item == 998 ? 'Profile added to Level-V' :
										 'Profile added to Recommended',
					type : 'success'
				})
				console.log(response)
				
			
			setFlagLoading(false)
			
		}catch(error){
			console.log(error)
		}
		
	}
	async function createshortlist() {
		setshortlistloading(true)
		const CreateUserInput = {
			UserId: profile.UserId,
			// businessUserId: userId,
			candidateprofileId: profile.id,
			preferencesId: route?.params?.PreferenceId
				? route?.params?.PreferenceId
				: PreferenceId,
			businessProfileId: companyProfileId,
			shortlistedbyId: companyuserprofileId,
			JobPostId: route?.params?.JobPostId
				? route?.params?.JobPostId
				: JobPostId,
		}
		API.graphql({
			query: createShortlist,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		}).then((res) => {
			setshortlisted(true)
			setshortlistloading(false)
		})
	}
	async function createRecommendedShortlist(props) {
		setSelectedLoading(true)

		const CreateUserInput = {
			id: RecommendationId
				? RecommendationId
				: route.params?.RecommendationId,
			acceptanceStatus: props,
			ReasonofShortlist: '',
			// Reasonofrejection: String
			acceptanceStatus_by_CompanyUser_ID: companyuserprofileId,
			acceptanceStatus_by_CompanyUser_name: companyUsername,
		}
		API.graphql({
			query: updateRecommendedprofile,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		})
			.then((res) => {
				
				if (props == 'Shortlisted') {
					setTimeout(() => {
						
						setshortlisted(true)
						setSelectedLoading(false)

						showToast({
							message: `Profile moved in ${props} Section`,
							type: 'success',
						})
					}, 1800)
					const data = {
						EmailBody: 'Shortlist',
						Emails: [profile.Email],
						EmailSubject: `Congratulations! You've Been Shortlisted for the  ${
							route?.params?.JobPostData?.CompanyPreference?.rolename
							? route?.params?.JobPostData?.CompanyPreference?.rolename
							: JobPostData?.CompanyPreference?.rolename
						}`,
						Link: `https://app.qrusible.com/Q-profile/${profile.UserId}`,
						CandidateName: profile?.name,
						TypeOfMailenum: 'OnShortlist',
						Jobtitle: route?.params?.JobPostData.CompanyPreference.rolename
							? route?.params?.JobPostData.CompanyPreference.rolename
							: JobPostData.CompanyPreference?.rolename,
						EmployerName: route?.params?.JobPostData?.CompanyPreference?.companyprofile?.name
							? route?.params?.JobPostData?.CompanyPreference?.companyprofile?.name
							: JobPostData?.CompanyPreference?.companyprofile?.name,
						UserId: profile.UserId,
					}
					CallEmailLambda(data)
				} else if (props == 'Rejected') {
					
					setTimeout(() => {
						setrejected(true)
						setSelectedLoading(false)
						showToast({
							message: `Profile moved in ${props} Section`,

							type: 'success',
						})
						const data = {
							EmailBody: 'Reject',
							Emails: [profile.Email],
							EmailSubject: `Update on Your Application Status`,
							Link: `https://app.qrusible.com/Q-profile/${profile.UserId}`,
							CandidateName: profile.name,
							TypeOfMailenum: 'OnReject',
							Jobtitle: route?.params?.JobPostData.CompanyPreference.rolename
							? route?.params?.JobPostData.CompanyPreference.rolename
							: JobPostData.CompanyPreference.rolename,
						EmployerName: route?.params?.JobPostData?.CompanyPreference?.companyprofile?.name
							? route?.params?.JobPostData?.CompanyPreference?.companyprofile?.name
							: JobPostData?.CompanyPreference?.companyprofile?.name,
							UserId: profile.UserId,
						}
						CallEmailLambda(data)
					}, 1800)
				} else if (props == 'Deployed') {
					CreateDeployedQrew()
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function updateReasonofShortlist() {
		

		const CreateUserInput = {
			id: RecommendationId
				? RecommendationId
				: route.params?.RecommendationId,
				ReasonofRecommendation: reasonOfRecommendationInput,
			// Reasonofrejection: String
			
		}
		API.graphql({
			query: updateRecommendedprofile,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		})
			.then((res) => {
			
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function CreateDeployedQrew() {
		setSelectedLoading(true)
		
		const CreateUserInput = {
			companyQrewId: companyQrewId,
			businessProfileId: companyProfileId,

			candidateprofileId: profile.id,
			preferencesId: route?.params?.PreferenceId
				? route?.params?.PreferenceId
				: PreferenceId,
			recommededId: RecommendationId
				? RecommendationId
				: route.params?.RecommendationId,

			JobApplicationsId: JobApplicantId,
		}
		API.graphql({
			query: createDeployedQrew,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		})
			.then((res) => {
				showToast({
					message: `Candidate Deployed Successfully`,
					type: 'success',
				})
				console.log("deployed")
			})
			.catch((err) => {
				console.log(err)
			})
	}

	async function RemoveAcceptanceStatus() {
		setSelectedLoading(true)

		const CreateUserInput = {
			id: RecommendationId
				? RecommendationId
				: route.params?.RecommendationId,
			acceptanceStatus: null,
			ReasonofShortlist: '',
			// Reasonofrejection: String
			acceptanceStatus_by_CompanyUser_ID: companyuserprofileId,
			acceptanceStatus_by_CompanyUser_name: companyUsername,
		}
		API.graphql({
			query: updateRecommendedprofile,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		})
			.then((res) => {
				setTimeout(() => {
					setSelectedLoading(false)
					showToast({
						message: `Profile moved back to Q-Rated Section`,

						type: 'success',
					})
				}, 2000)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function createrejected() {
		setrejectloading(true)
		const CreateUserInput = {
			UserId: profile.UserId,
			// businessUserId: userId,
			candidateprofileId: profile.id,
			preferencesId: route?.params?.PreferenceId
				? route?.params?.PreferenceId
				: PreferenceId,
			businessProfileId: companyProfileId,
			shortlistedbyId: companyuserprofileId,
			Reasonofrejection: 'Reason OF rejecting ',
			JobPostId: route?.params?.JobPostId
				? route?.params?.JobPostId
				: JobPostId,
		}
		API.graphql({
			query: createRejected,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		}).then((res) => {
			setrejected(true)
			setrejectloading(false)
		})
	}
	
	async function createrecommendedprofile() {
		if(ProfileAnalysisVariable != undefined){
			setrecommendloading(true)
			const CreateUserInput = {
			UserId: profile.UserId,
			// businessUserId: userId,
			candidateprofileId: profile.id,
			preferencesId: route?.params?.PreferenceId
				? route?.params?.PreferenceId
				: PreferenceId,
			businessProfileId: companyProfileId,
			recommendedbyId: companyuserprofileId,
			JobPostId: route?.params?.JobPostData.id
				? route?.params?.JobPostData.id
				: JobPostData.id,
			JobApplicationsId: route?.params?.JobApplicantId
				? JobApplicantId
				: JobApplicantId,
				ReasonofRecommendation : reasonOfRecommendationInput,
				companyQrewId : companyQrewId
		}
		API.graphql({
			query: createRecommendedprofile,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		}).then((res) => {
			showToast({
				message: `Recommended Profile Successfully`,
				type: 'success',
			})
			setrecommend(true)
			setrecommendloading(false)
			const data = {
				EmailBody: 'Recommendation',
				Emails: [profile.Email],
				EmailSubject: `Qrusible has recommended your Profile for ${
					route?.params?.JobPostData.CompanyPreference.rolename
							? route?.params?.JobPostData.CompanyPreference.rolename
							: JobPostData.CompanyPreference.rolename
				}`,
				Link: `https://app.qrusible.com/Q-profile/${profile.UserId}`,
				CandidateName: profile.name,
				TypeOfMailenum: 'OnRecommendation',
				Jobtitle: route?.params?.JobPostData.CompanyPreference.rolename
							? route?.params?.JobPostData.CompanyPreference.rolename
							: JobPostData.CompanyPreference.rolename,
						EmployerName: route?.params?.JobPostData?.CompanyPreference?.companyprofile?.name
							? route?.params?.JobPostData?.CompanyPreference?.companyprofile?.name
							: JobPostData?.CompanyPreference?.companyprofile?.name,
				UserId: profile.UserId,
			}
			CallEmailLambda(data)
			UpdatetoAplhaOrQfactor(1001)
		})
	}
	else{
		showToast({
			message: `Profile Analysis is not available`,
			type: 'error',
		})
	}
	}

	async function getprofile() {
		setloading(true)

		await API.graphql({
			query: getNewprofileByUserIdForDashboardInsights,
			variables: {
				UserId: route?.params?.UserId ? route?.params?.UserId : UserId,
			},
		})
			.then((res) => {
				
				setProfile(res.data.getNewprofileByUserId.items[0])

				const candidatePreferences =
					res?.data?.getNewprofileByUserId?.items[0]
						?.CandidatePreferences?.items[0]
						setPreferences(candidatePreferences)
						setPreferencesContract(candidatePreferences)
				
				setHidis(res.data.getNewprofileByUserId.items[0].hidis?.items)
				setloading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	useEffect(() => {
		getprofile()
	}, [UserId, RecommendationId, route?.params?.UserId])

	function formatDate(inputDate) {
		const date = new Date(inputDate)

		const day = String(date.getDate()).padStart(2, '0')
		const month = String(date.getMonth() + 1).padStart(2, '0') // Month is 0-indexed, so we add 1.
		const year = date.getFullYear()

		return `${day}/${month}/${year}`
	}
	function findMomentsPassed(inputDate) {
		const currentDate = new Date()
		const inputDateTime = new Date(inputDate)

		const timeDiff = Math.abs(
			currentDate.getTime() - inputDateTime.getTime()
		)
		const diffSeconds = Math.floor(timeDiff / 1000)
		const diffMinutes = Math.floor(diffSeconds / 60)
		const diffHours = Math.floor(diffMinutes / 60)
		const diffDays = Math.floor(diffHours / 24)
		const diffMonths = Math.floor(diffDays / 30)
		const diffYears = Math.floor(diffMonths / 12)
		const remainingMonths = diffMonths % 12
		const remainingDays = diffDays % 30
		const remainingHours = diffHours % 24
		const remainingMinutes = diffMinutes % 60

		if (diffYears === 0) {
			if (diffMonths === 0) {
				if (diffDays === 0) {
					if (diffHours === 0) {
						if (diffMinutes === 0) {
							if (diffSeconds < 60) {
								return 'Just Now'
							} else {
								return `${diffSeconds} second${
									diffSeconds > 1 ? 's' : ''
								} ago`
							}
						} else {
							return `${diffMinutes} minute${
								diffMinutes > 1 ? 's' : ''
							} ago`
						}
					} else {
						return `${diffHours} hour${
							diffHours > 1 ? 's' : ''
						} ago`
					}
				} else {
					return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`
				}
			} else {
				if (remainingDays === 0) {
					return `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`
				} else {
					return `${diffMonths} month${
						diffMonths > 1 ? 's' : ''
					} and ${remainingDays} day${
						remainingDays > 1 ? 's' : ''
					} ago`
				}
			}
		} else {
			if (remainingMonths === 0) {
				return `${diffYears} year${diffYears > 1 ? 's' : ''} ago`
			} else {
				return `${diffYears} year${
					diffYears > 1 ? 's' : ''
				} and ${remainingMonths} month${
					remainingMonths > 1 ? 's' : ''
				} ago`
			}
		}
	}

	const capabilitiesItems = profile?.Capabilities?.items
	const capabilitiesItemsLastItem = profile?.Capabilities?.items?.length - 1
	const hidiItems = profile?.hidis?.items
	const hidiItemsLastItem = profile?.hidis?.items?.length - 1

	const HidiNewComponent = () => {
		
		return (
			<Box
				borderWidth={1}
				borderColor="$borderDark0"
				borderRadius={6}
				marginTop={windowWidth > 480 ? 20 : 10}
				padding={windowWidth > 480 ? 10 : 5}
				marginBottom={windowWidth > 480 ? 10 : 5}
			>
				<CapabilityHidiOnProfile
					UnlockedCapability={matchingCapabilities}
					navigation={navigation}
					PreferenceId={
						PreferenceId ? PreferenceId : route.params?.PreferenceId
					}
					capabilitiesClaimedLength={`${
						matchedClaimedCapabilities?.length +
						matchingCapabilities?.length
					} / ${CapabilityRequiredforJob?.length}`}
					evidences={matchingCapabilities?.length}
					capabilities={remainingCapability}
					capabilitiesClaimed={CapabilityClaimed}
					
				/>
				<CapabilityHidiOnProfile
					UnlockedCapability={unmatchedCapabilitiesWithEvidence}
					evidences={unmatchedCapabilitiesWithEvidence?.length}
					navigation={navigation}
					PreferenceId={
						PreferenceId ? PreferenceId : route.params?.PreferenceId
					}
					// capabilitiesClaimedLength={`${
					// 	matchedClaimedCapabilities?.length +
					// 	matchingCapabilities?.length
					// } / ${CapabilityRequiredforJob?.length}`}
					// evidences={matchingCapabilities?.length}
					// capabilities={remainingCapability}
					// capabilitiesClaimed={CapabilityClaimed}
					unmatchedCapabilityClaimed={unmatchedCapabilityClaimed}
				/>
			</Box>
		)
	}

	// currency selected by user

	useEffect(() => {
		const fetchData = async () => {
			try {
				const countriesInfo = await fetchCurrenciesInfo(apiUrl)
				setCurrencies(countriesInfo)
			} catch (error) {
				console.error('Error in component:', error)
			}
		}

		fetchData()
	}, [apiUrl])

	useEffect(() => {
		const currencyCountryName =
			JobPostData?.CompanyPreference?.currencyCountry || null

		if (currencies && currencyCountryName) {
			const selectedCountry = currencies.find(
				(country) => country.countryName === currencyCountryName
			)

			if (selectedCountry) {
				const { name, currencySymbol } = selectedCountry

				setSelectedCountryInfo({ name, currencySymbol })
			} else {
				setSelectedCountryInfo(null)
				console.warn(
					'Country not found in currencies:',
					currencyCountryName
				)
			}
		} else {
			setSelectedCountryInfo(null)
		}
	}, [currencies, preferences, preferencesContract])

	// currency conversion

	useEffect(() => {
		const targetCountry =
			preferences?.CurrencyCountry ||
			preferencesContract?.CurrencyCountry ||
			null

		if (targetCountry && baseCountry && currentSalary) {
			const convertedCurrentAmount = convertCurrency(
				baseCountry,
				targetCountry,
				currentSalary
			)
			if (convertedCurrentAmount !== null) {
				setConvertedCurrentSalary(convertedCurrentAmount)
			}
		} else {
			setConvertedCurrentSalary(null)
		}
		if (targetCountry && baseCountry && expectedSalary) {
			const convertedExpectedAmount = convertCurrency(
				baseCountry,
				targetCountry,
				expectedSalary
			)
			if (convertedExpectedAmount !== null) {
				setConvertedExpectedSalary(convertedExpectedAmount)
			}
		} else {
			setConvertedExpectedSalary(null)
		}
	}, [
		baseCountry,
		currentSalary,
		expectedSalary,
		preferences,
		preferencesContract,
	])

	const AllPreferenceData = [
		{
			title: 'Total Years of experience',
			stat: profile?.experience ? profile?.experience : null,
			companyPref: JobPostDataProps?.CompanyPreference?.minExperienceRequired
				? `${JobPostDataProps?.CompanyPreference?.minExperienceRequired} Years`
				: null,
		},
		{
			title: 'Expected Salary (Per Year)',
			stat: preferences?.Salaryexpectation
				? `${
						selectedCountryInfo
							? selectedCountryInfo?.currencySymbol
							: ''
				  } ${
						convertedExpectedSalary
							? convertedExpectedSalary
							: expectedSalary
							? expectedSalary
							: null
				  }`
				: null,
			companyPref: JobPostDataProps?.CompanyPreference?.package
				? `${JobPostDataProps?.CompanyPreference?.package}`
				: null,
		},

		{
			title: 'Current Salary (Per Year)',
			companyPref: null,
			stat: preferences?.currentSalary
				? `${
						selectedCountryInfo
							? selectedCountryInfo?.currencySymbol
							: ''
				  } ${
						convertedCurrentSalary
							? convertedCurrentSalary
							: currentSalary
							? currentSalary
							: null
				  }`
				: null,
		},
		{
			title: 'Serving Notice',
			companyPref: null,
			stat: preferences?.servingNotice,
		},
		{
			title: 'Notice Period',
			companyPref: null,
			stat: preferences?.noticePeriod
				? `${preferences?.noticePeriod} Days`
				: JobPostDataProps?.CompanyPreference?.noticeperiod,
		},
		{
			title: 'Country',
			companyPref: null,
			stat: preferences?.Country ? preferences?.Country : null,
		},
		{
			title: 'Location Preference',
			companyPref: null,
			stat: preferences?.location1
				? `${preferences?.location1 ? preferences?.location1 : ''}, ${
						preferences?.location2 ? preferences?.location2 : ''
				  }, ${preferences?.location3 ? preferences?.location3 : ''}`
				: null,
		},

		{
			title: 'Available To Join From',
			companyPref: null,
			stat: preferences?.AvailableDate
				? formatDate(preferences?.AvailableDate)
				: preferencesContract?.AvailableDate
				? formatDate(preferencesContract?.AvailableDate)
				: null,
		},
		{
			title: 'Minimum Contract Period',
			companyPref: null,
			stat: preferencesContract?.minimumContractPeriod
				? preferencesContract?.minimumContractPeriod
				: null,
		},
		{
			title: 'Type of Contract',
			companyPref: null,
			stat: preferencesContract?.contractType
				? preferencesContract?.contractType
				: null,
		},

		{
			title: 'Compensation Structure',
			companyPref: null,
			stat: preferencesContract?.compensationStructure
				? `${
						selectedCountryInfo
							? selectedCountryInfo?.currencySymbol
							: ''
				  } ${preferencesContract?.compensationStructure}`
				: null,
		},
		{
			title: 'Expected on contract (Per Year)',
			companyPref: JobPostDataProps?.CompanyPreference?.package
				? `${JobPostDataProps?.CompanyPreference?.package}`
				: null,
			stat: preferencesContract?.ContractCompensation
				? preferencesContract?.ContractCompensation
				: null,
		},
		{
			title: 'Available To Join From',
			companyPref: null,
			stat: preferences?.AvailableDate
				? formatDate(preferences?.AvailableDate)
				: preferencesContract?.AvailableDate
				? formatDate(preferencesContract?.AvailableDate)
				: null,
		},{
			title: 'Hourly Rate',
			companyPref: null,
			stat: preferencesContract?.HourlyRateExpectedOnContract
				
		},
	]

	
		
	

	if (typeofuser == 'QrusibleSuperAdmin') {
	AllPreferenceData.push(
			{ title: 'Email', stat: profile?.Email },
			{ title: 'Phone', stat: profile?.phone },
			{
				title: 'Last Updated',
				stat: findMomentsPassed(profile?.updatedAt),
			},
			{
				title: 'Last Updated Capability',
				stat:
					capabilitiesItems?.length > 0
						? findMomentsPassed(
								capabilitiesItems[capabilitiesItems.length - 1]
									?.createdAt
						  )
						: null,
			},
			{
				title: 'Last Updated Experience',
				stat:
					hidiItems?.length > 0
						? findMomentsPassed(
								hidiItems[hidiItems.length - 1]?.createdAt
						  )
						: null,
			}
		)
		
	}
	const AllPreferenceDataFilter = AllPreferenceData.filter(
		(item) => item.stat !== null && item.stat !== undefined && item !== ''
	)
	// const filteredContractData = contractData.filter(
	// 	(item) => item.stat !== null && item.stat !== undefined && item !== ''
	// )


	const renderStatsDisplayComponent = ({ item }) => {

		const string = item.stat
		
		return (
			<>
				{item.stat ? (
					<Box
						flex={item.stat ? 1 : 0}
						w={item?.stat ? null : 0}
						marginVertical={windowWidth > 480 ? 10 : 5}
						marginHorizontal={windowWidth > 480 ? 5 : 3}
					>
						{item.title == 'Expected on contract' ||
						item.title == 'Expected Salary (Per Year)' ? (
							<>
								{parseInt(string.match(/\d+/)?.[0]) <=
								prefferedSalary  ? (
									<HStack>
										<Image
											h={18}
											w={18}
											marginRight={2}
											source={require('../../assets/Images/checkicon.png')}
										/>
										<Text
											fontFamily="$fontHeading"
											fontSize={
												windowWidth > 480
													? '$fontSize15'
													: 13
											}
											lineHeight={18}
											color="$primary950"
											fontWeight="700"
										>
											{item.title}
										</Text>
									</HStack>
								) : (
									<HStack>
										<Image
											h={18}
											w={18}
											marginRight={2}
											source={require('../../assets/Images/redcrossicon.png')}
										/>
										<Text
											fontFamily="$fontHeading"
											fontSize={
												windowWidth > 480
													? '$fontSize15'
													: 13
											}
											lineHeight={18}
											color="$primary950"
											fontWeight="700"
										>
											{item.title}
										</Text>
									</HStack>
								)}
							</>
						) : (
							<>
								{item.title == 'Total Years of experience' ? (
									<>
										{parseInt(string.match(/\d+/)?.[0]) >=
											minExperience &&
										parseInt(string.match(/\d+/)?.[0]) <=
											maxExpeirence ? (
											<HStack>
												<Image
													h={18}
													w={18}
													marginRight={2}
													source={require('../../assets/Images/checkicon.png')}
												/>
												<Text
													fontFamily="$fontHeading"
													fontSize={
														windowWidth > 480
															? '$fontSize15'
															: 13
													}
													lineHeight={18}
													color="$primary950"
													fontWeight="700"
												>
													{item.title}
												</Text>
											</HStack>
										) : (
											<HStack>
												<Image
													h={18}
													w={18}
													marginRight={2}
													source={require('../../assets/Images/redcrossicon.png')}
												/>
												<Text
													fontFamily="$fontHeading"
													fontSize={
														windowWidth > 480
															? '$fontSize15'
															: 13
													}
													lineHeight={18}
													color="$primary950"
													fontWeight="700"
												>
													{item.title}
												</Text>
											</HStack>
										)}
									</>
								) : (
									<Text
										fontFamily="$fontHeading"
										fontSize={
											windowWidth > 480
												? '$fontSize15'
												: 13
										}
										lineHeight={18}
										color="$primary950"
										fontWeight="700"
										marginLeft={
											item.title ==
												'Expected on contract' ||
											item.title ==
												'Expected Salary (Per Year)' ||
											item.title ==
												'Total Years of experience'
												? 0
												: 20
										}
									>
										{item.title}
									</Text>
								)}
							</>
						)}

						{item.companyPref && (
							<Text
								fontFamily="$fontHeading"
								fontSize={windowWidth > 480 ? 12 : 10}
								lineHeight={18}
								color="$fontGray"
								marginLeft={20}
							>
								Company's Preference: {item.companyPref}
							</Text>
						)}
						<Text
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? '$fontSize15' : 13}
							lineHeight={18}
							color="$primary950"
							marginLeft={20}
							textAlign="left"
						>
							{item.stat}
						</Text>
					</Box>
				) : null}
			</>
		)
	}

	const InsightsComponent = () => {
		return (
			<Box
				borderWidth={1}
				padding={windowWidth > 480 ? 20 : 10}
				borderRadius={6}
				borderColor="$borderDark0"
				marginVertical={windowWidth > 480 ? 15 : 5}
			>
				<Text
					fontFamily="$fontHeading"
					fontSize={windowWidth > 480 ? 20 : 18}
					color="$primary950"
					fontWeight={700}
				>
					Candidate Insights
				</Text>
				<Divider
					marginVertical={windowWidth > 480 ? 25 : 18}
					bg="$borderDark0"
				/>
				{ReasonofRecommendation && (
					<Box
						borderWidth={1}
						borderColor="$borderDark0"
						borderRadius={6}
						gap={windowWidth > 480 ? 20 : 10}
						marginBottom={20}
					>
						<Text
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 20 : 18}
							color="$primary950"
							fontWeight={700}
							padding={15}
						>
							Candidate Analysis
						</Text>
						<Divider
							bg="$borderDark0"
							width={'94%'}
							alignSelf="center"
						/>
						<Text
							marginLeft={4}
							fontFamily="$fontContent"
							fontSize={windowWidth > 480 ? 14 : 12}
							color="$primary950"
							fontWeight={200}
							padding={15}
						>
							{ReasonofRecommendation}
						</Text>
					</Box>
				)}

				<Box
					borderWidth={1}
					borderColor="$borderDark0"
					borderRadius={6}
				>
					<HStack
						justifyContent="space-between"
						// marginVertical={20}
						p={windowWidth > 480 ? 20 : 10}
						flexWrap="wrap"
						gap={windowWidth > 480 ? 20 : 10}
					>
						<Text
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 20 : 18}
							color="$primary950"
							fontWeight={700}
						>
							Preferences
						</Text>
						<Divider bg="$borderDark0" />
						<FlatList
							data={
								AllPreferenceDataFilter
							}
							renderItem={renderStatsDisplayComponent}
							keyExtractor={(index) => index.toString()}
							numColumns={windowWidth > 480 ? 2 : 1}
						/>
					</HStack>
				</Box>

				<HidiNewComponent />
			</Box>
		)
	}

	const ExpereiceAndEducationCOmponent = () => {
		return (
			<>
				<WorkExperience userId={profile?.UserId} screen={'public'} />
				<EducationDetails userId={profile?.UserId} screen={'public'} />
				<CertificateComponent
					userId={profile?.UserId}
					screen={'public'}
				/>
			</>
		)
	}

	const ShortListButton = () => {
		return (
			<Box
				alignItems="flex-start"
				justifyContent="space-between"
				flexDirection="row"
				marginVertical={windowWidth > 480 ? 15 : 7}
			>
				{acceptanceStatus == 'Shortlisted' ? (
					<Pressable
						disabled={true}
						borderWidth={1}
						borderRadius={10}
						bgColor="$blueGray200"
						borderColor="$blueGray200"
						alignItems="center"
						justifyContent="center"
						marginBottom={5}
						h={30}
						width={windowWidth > 480 ? 150 : 100}
						onPress={() => {}}
					>
						{shortlistloading ? (
							<SpinnerComponent />
						) : (
							<Text
								color="$grey"
								fontWeight={700}
								fontFamily="$fontHeading"
							>
								Shortlisted
							</Text>
						)}
					</Pressable>
				) : (
					<Pressable
						borderWidth={1}
						borderRadius={10}
						bgColor="$primary400"
						alignItems="center"
						justifyContent="center"
						borderColor="$primary400"
						marginBottom={5}
						h={30}
						width={windowWidth > 480 ? 150 : 100}
						onPress={() =>
							headingText == 'Recommended'
								? createRecommendedShortlist('Shortlisted')
								: createshortlist()
						}
					>
						{shortlistloading ? (
							<Box alignSelf="center" marginTop={10}>
								<SpinnerComponent />
							</Box>
						) : (
							<Text
								color="$white"
								fontWeight={700}
								fontFamily="$fontHeading"
							>
								Shortlist
							</Text>
						)}
					</Pressable>
				)}
			</Box>
		)
	}
	const RejectedButton = () => {
		return (
			<Box
				alignItems="flex-start"
				justifyContent="space-between"
				flexDirection="row"
				marginVertical={windowWidth > 480 ? 15 : 7}
			>
				{acceptanceStatus == 'Rejected' ? (
					<Pressable
						disabled={true}
						borderWidth={1}
						borderRadius={10}
						bgColor="$blueGray200"
						borderColor="$blueGray200"
						alignItems="center"
						justifyContent="center"
						marginBottom={5}
						h={30}
						alignContent="center"
						width={windowWidth > 480 ? 150 : 100}
						onPress={() => {}}
					>
						{rejectloading ? (
							<Box alignSelf="center" marginTop={10}>
								<SpinnerComponent color="$white" />
							</Box>
						) : (
							<Text
								color="$grey"
								fontWeight={700}
								fontFamily="$fontHeading"
							>
								Rejected
							</Text>
						)}
					</Pressable>
				) : (
					<Pressable
						borderWidth={1}
						borderRadius={10}
						bgColor="red"
						alignItems="center"
						justifyContent="center"
						alignContent="center"
						borderColor="$primary400"
						marginBottom={5}
						h={30}
						width={windowWidth > 480 ? 150 : 100}
						onPress={() =>
							headingText == 'Recommended'
								? createRecommendedShortlist('Rejected')
								: createrejected()
						}
					>
						{rejectloading ? (
							<Box alignSelf="center" marginTop={10}>
								<SpinnerComponent color="$white" />
							</Box>
						) : (
							<Text
								color="$white"
								fontWeight={700}
								fontFamily="$fontHeading"
							>
								Reject
							</Text>
						)}
					</Pressable>
				)}
			</Box>
		)
	}
	const RecommendedButton = () => {
		return (
			<Box
				alignItems="flex-start"
				justifyContent="space-between"
				flexDirection="row"
				alignContent="center"
				marginVertical={windowWidth > 480 ? 15 : 7}
			>
				{recommend ? (
					<InactiveButtonComponent
						text={'Recommended'}
						width={'fit-content'}
						onPress={() => {}}
						height={30}
					/>
				) : (
					<ActiveButtonComponent
						text={'Recommend'}
						width={'fit-content'}
						onPress={() => 
							// createrecommendedprofile()
							setreasonOfRecommendationModal(true)
						
						}
						height={30}
					/>
				)}
			</Box>
		)
	}
	const UpdateReasonofRecommendationButton = () => {
		return (
			<Box
				alignItems="flex-start"
				justifyContent="space-between"
				flexDirection="row"
				alignContent="center"
				marginVertical={windowWidth > 480 ? 15 : 7}
			>
				
					<ActiveButtonComponent
						text={'Update ReasonOfReason'}
						width={'fit-content'}
						onPress={() => 
							// createrecommendedprofile()
							setreasonOfRecommendationModal(true)
						
						}
						height={30}
					/>
				
			</Box>
		)
	}
	const RemoveFromList = () => {
		return (
			<Box
				alignItems="flex-end"
				alignSelf="flex-end"
				justifyContent="space-between"
				alignContent="center"
				marginVertical={windowWidth > 480 ? 15 : 7}
			>
				<Pressable
					borderWidth={1}
					borderRadius={10}
					bgColor="red"
					borderColor="red"
					alignItems="center"
					justifyContent="center"
					marginBottom={5}
					h={30}
					width={windowWidth > 480 ? 150 : 150}
					onPress={() => {
						RemoveAcceptanceStatus()
					}}
				>
					{RemoveLoading ? (
						<Box alignSelf="center" marginTop={10}>
							<SpinnerComponent color="$white" />
						</Box>
					) : (
						<Text
							color="$white"
							fontWeight={700}
							fontFamily="$fontHeading"
						>
							Remove From List
						</Text>
					)}
				</Pressable>
			</Box>
		)
	}

	

	const triggerLambda = async () => {
		setLambdaLoading(true)
		await parseResume(
			route?.params?.UserId ? route?.params?.UserId : UserId
		).then(() => {
			getprofile()
		})
		setLambdaLoading(false)
	}
	const LambdaAnalysis = async (input) => {
		setLambdaLoading(true)
		try {
			const response = await API.graphql({
				query: ProfileAnalysis,
				authMode: 'AMAZON_COGNITO_USER_POOLS',
				variables: {
					JobPostid: JobPostDataProps.id,
					profileid: profile.id,
					JobApplicationId: JobApplicantId,
					Flag: 'Trigger',
				},
			}).then((res) => {
				console.log(res, "res")
				GetJobApplications()
			})
		} catch (error) {
			console.log('Error creating experience', error)
		}
	}
	const GetJobApplications = async () => {
		try {
			const response = await API.graphql({
				query: getJobApplications,
				authMode: 'AMAZON_COGNITO_USER_POOLS',
				variables: {
					id: JobApplicantId,
				},
			})

			setProfileAnalysisVariable(
				response.data?.getJobApplications?.CandidateProfileAnalysis
			)

			setLambdaLoading(false)
		} catch (error) {
			console.log('Error creating experience', error)
		}
	}
	const TriggerLambdaButton = () => {
		return (
			<LightButtonComponent
				text={'Fetch skills'}
				onPress={triggerLambda}
				loading={lambdaLoading}
				width={windowWidth > 480 ? 150 : 100}
				height={30}
			/>
		)
	}
	const TriggerLambdaAnalysisButton = () => {
		return (
			<LightButtonComponent
				text={'Analyse'}
				onPress={LambdaAnalysis}
				loading={lambdaLoading}
				width={windowWidth > 480 ? 150 : 100}
				height={30}
			/>
		)
	}
	const AddAlphaProfile = () => {
		return (
			<LightButtonComponent
				text={'Mark Alpha'}
				onPress={() => UpdatetoAplhaOrQfactor(999)}
				disabled={SelectedTabScreen == 'LevelAlpha' ? true : false}
				loading={FlagLoading}
				width={windowWidth > 480 ? 150 : 100}
				height={30}
			/>
		)
	}
	const AddLevelVProfile = () => {
		return (
			<LightButtonComponent
				text={'Mark Level V'}
				onPress={() => UpdatetoAplhaOrQfactor(998)}
				disabled={SelectedTabScreen == 'Level-V' ? true : false}
				loading={FlagLoading}
				width={windowWidth > 480 ? 150 : 100}
				height={30}
			/>
		)
	}
	const AddtoQFactor = () => {
		return (
			<LightButtonComponent
				text={'Q-Factor'}
				onPress={() => UpdatetoAplhaOrQfactor(1000)}
				disabled={SelectedTabScreen == 'Q-Factor' ? true : false}
				loading={FlagLoading}
				width={windowWidth > 480 ? 150 : 100}
				height={30}
			/>
		)
	}
	

	return (
		<>
			{loading ? (
				<SpinnerComponent size={'small'} button={false} />
			) : (
				<>
					{windowWidth < 480 && (
						<MobileHeader
							props={'Candidate Profile'}
							navigation={navigation}
						/>
					)}
					{windowWidth > 480 ? (
						<Box>
							<ScrollView
								maxHeight={windowHeight + 80}
								showsVerticalScrollIndicator={false}
							>
								<Box
									bg="$primary0"
									width={'100%'}
									marginBottom={windowWidth > 480 ? null : 50}
									paddingHorizontal={
										windowWidth > 480 ? 10 : 5
									}
									paddingVertical={windowWidth > 480 ? 10 : 5}
									paddingBottom={
										windowWidth > 480 ? null : 50
									}
								>
									<ModalComponent
									renderBody={renderReasonOfRecommendationnModal}
									isOpen={reasonOfRecommendationModal}
									onClose={() => {
										setreasonOfRecommendationModal(false)
									}}
									hideCloseButton={true}
									showScroll={false}
									padding={0}
								/>
									<Box>
										{!checkloading && (
											<HStack
												justifyContent="Right"
												alignItems="center"
												gap="10px"
											>
												{typeofuser ==
												'QrusibleSuperAdmin' ? (
													<RecommendedButton />
												) : null}
												{typeofuser ==
												'QrusibleSuperAdmin'  && headingText == 'Recommended' &&
													<UpdateReasonofRecommendationButton /> 
												}
												{typeofuser ==
													'QrusibleSuperAdmin' &&
													headingText !=
														'Recommended' && (
														<TriggerLambdaAnalysisButton />
													)}
													{typeofuser ==
													'QrusibleSuperAdmin' &&
													headingText !=
														'Recommended' && (
														<AddLevelVProfile />
													)}
													{typeofuser ==
													'QrusibleSuperAdmin' &&
													headingText !=
														'Recommended' && (
														<AddAlphaProfile />
													)}
													
													{typeofuser ==
													'QrusibleSuperAdmin' &&
													headingText !=
														'Recommended' && (
														<AddtoQFactor />
													)}
												{typeofuser ==
												'QrusibleSuperAdmin' ? (
													<TriggerLambdaButton />
												) : null}
											</HStack>
										)}
									</Box>

									<AboutBox
										props={profile}
										publicState={'public'}
									/>
									{ProfileAnalysisVariable &&
										 (
											<ProfileAnalysisComponent
												preferencesId={PreferenceId}
												props={ProfileAnalysisVariable}
											/>
										)}

									{profile?.IntroVideo && (
										<VideoViewerComponent
											keyParam={`${
												route?.params?.UserId
													? route?.params?.UserId
													: UserId
											}_video`}
											introVideo={profile?.IntroVideo}
										/>
									)}

									<SkillsComponent
										screen={'public'}
										userId={
											route?.params?.UserId
												? route?.params?.UserId
												: UserId
										}
									/>
									{acceptanceStatus != 'Deployed' && (
										<>
											{headingText !== 'Applicants' && (
												<AcceptanceDropdown />
											)}
										</>
									)}

									<InsightsComponent />

									<ExpereiceAndEducationCOmponent />

									<ResumeView
										userId={
											UserId
												? UserId
												: route?.params?.UserId
										}
									/>
								</Box>
							</ScrollView>
						</Box>
					) : (
						<>
							<Box
								bg="$primary0"
								// minHeight={windowHeight - 62}
								marginTop={62}
								marginBottom={windowWidth > 480 ? null : 50}
								paddingHorizontal={windowWidth > 480 ? 10 : 5}
								paddingVertical={windowWidth > 480 ? 10 : 5}
								paddingBottom={windowWidth > 480 ? null : 50}
							>
								{!checkloading && (
									<>
										{typeofuser == 'QrusibleSuperAdmin' ? (
											<RecommendedButton />
										) : null}
									</>
								)}
								<AboutBox
									props={profile}
									publicState={'public'}
								/>
								{ProfileAnalysisVariable &&
										 (
											<ProfileAnalysisComponent
												preferencesId={PreferenceId}
												props={ProfileAnalysisVariable}
											/>
										)}
								{profile?.IntroVideo && (
									<VideoViewerComponent
										keyParam={`${
											route?.params?.UserId
												? route?.params?.UserId
												: UserId
										}_video`}
										introVideo={profile?.IntroVideo}
									/>
								)}

								<SkillsComponent
									screen={'public'}
									userId={
										route?.params?.UserId
											? route?.params?.UserId
											: UserId
									}
								/>

								<ModalComponent
									renderBody={renderReasonOfRecommendationnModal}
									isOpen={reasonOfRecommendationModal}
									onClose={() => {
										setreasonOfRecommendationModal(false)
									}}
									hideCloseButton={true}
									showScroll={false}
									padding={0}
								/>
								{headingText !== 'Applicants' && (
									<AcceptanceDropdown />
								)}
								<InsightsComponent />
								<ExpereiceAndEducationCOmponent />
								<ResumeView
									userId={
										UserId ? UserId : route?.params?.UserId
									}
								/>
							</Box>
						</>
					)}
				</>
			)}
		</>
	)
}
